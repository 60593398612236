import React, { Component } from "react";
import ReactBootstrapSlider from "react-bootstrap-slider";
import Select, { createFilter } from "react-select";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import HighchartsReact from "highcharts-react-official";
import highcharts from "highcharts";
import LiveMatrix from "../../../components/liveMatrix";
import LastView from "../../../components/lastView";
import PageHeader from "../../../components/pageHeader/pageHeader";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import MQSGUtil from "../../../components/mqsgUtil";
import AdvertBox from "../../../components/advert/advert";
import "./calculator.css";
import ReactTooltip from "react-tooltip";
import { LiveMatrixTodayTable, IndtableShow, TermsChart, WrCalculatorBox, LivematrixLogic, WarrantHedget } from "../../../components/WarrantTools";
class WarrantCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastviewrics: null,
      selected: null,
      symbols: [],
      tabNum: 2,
      period: 'Y',
      warrantdata: null,
      isLoading: true,
      chartData: [],
      livematrixdata: null,
      underlying: [],
    };
    this.logic = null;
    this.page = "calculator";
    this.initRedirect(props);
  }
  onFetchAllData(ric) {
    if (MQSGUtil.isUsingStaticData("LiveMatrix")) {
      setTimeout(() => {
        this.onFetchStaticData(ric);
      }, 100); // pretend fetch
      return;
    }
    const isLoading = false;
    const url =
      MQSGUtil.getAPIBasePath() + "/LiveMatrixJSON?ric=" + ric + "&mode=0";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          const livematrixdata = result;
          this.logic = new LivematrixLogic(
            livematrixdata.livematrix,
            livematrixdata.ric_data
          );
          this.setState({ livematrixdata, isLoading }, () => {
            const underlyingric = this.state.livematrixdata.ric_data.underlying_ric;
            this.onFetchChartData(
              underlyingric,
              ric,
              this.state.selected.period
            );
          });
        },
        (error) => {
          this.setState({ livematrixdata: null, isLoading });
        }
      );
  }
  onPeriodChange(period) {
    if (this.state.livematrixdata !== null) {
      const ric = this.state.selected.value;
      const underlyingric = this.state.livematrixdata.ric_data.underlying_ric;
      if (underlyingric !== null) {
        // const selectedNew = { ...this.state.selected };
        this.setState({ period: period, chartdata: null });
        this.onFetchChartData(underlyingric, ric, period);
      }
    }
  }
  onFetchChartData(underlyingRic, ric, period = this.state.period) {
    if (MQSGUtil.isUsingStaticData("Warrantterms")) {
      setTimeout(() => {
        this.onFetchStaticChartData(underlyingRic, ric, period);
      }, 100); // pretend fetch
      return;
    }
    const isLoadingChart = false;
    const ricString = underlyingRic + ";" + ric;
    let chartdata = null;
    const url =
      MQSGUtil.getAPIBasePath() +
      "/alert?TYPE=JSON&RIC=" +
      ricString +
      "&PERIODICITY=" +
      period +
      "&d=" +
      Date.now();
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          chartdata = result;
          this.setState({ chartdata, isLoadingChart });
        },
        (error) => {
          this.setState({ chartdata, isLoadingChart });
        }
      );
  }
  componentDidMount() {
    // if (window.location.href.indexOf('warrantterms') == -1) {
    //   this.onFetchSymbol();
    // }
    this.onFetchUnderlyingSymbol()
    if (!this.isRightPanel()) {
      document.title = "Warrant Calculator | Singapore Warrants | Macquarie"
      this.onFetchSymbol();
    }
    this.setState({ tabNum: 2 });
  }
  onFetchUnderlyingSymbol() {
    const url = MQSGUtil.getAPIBasePath() + "/UnderlyingRankingJSON?mode=2";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ underlying: result });
        },
        (error) => {
          this.setState({ underlying: [] });
        }
      );
  }
  onUnderlyingChange(option) {
    const url = MQSGUtil.getAPIBasePath() + "/SymbolsJSON?ric=" + option.underlying_ric;
    this.refs.selectWarrant.refs.warrant.onMenuOpen()
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ symbols: result });
        },
      );
  }
  initRedirect(props) {
    const match = MQSGUtil.getData(props, "match", null);
    if (match !== null) {
      const params = MQSGUtil.getData(match, "params", null);
      if (params !== null) {
        const ticker = MQSGUtil.getData(params, "ticker", null);
        if (ticker !== null) {
          this.ticker = ticker.toUpperCase();
        }
      }
    }
  }
  isRightPanel() {
    if (
      typeof this.props.isRightPanel !== "undefined" &&
      this.props.isRightPanel !== null
    ) {
      return this.props.isRightPanel;
    }
    return false;
  }

  initSelection(symbols, lastselection = null) {
    var lastselection = MQSGUtil.getLastSelection(this.page);
    let selected = null;
    if (symbols.length > 0) {
      if (this.ticker === undefined) {
        if (this.props.location.state !== undefined) {
          selected = {
            name: this.props.location.state.dsply_nmll,
            ric: this.props.location.state.ric,
            label:
              this.props.location.state.dsply_nmll +
              " (" +
              this.props.location.state.security_code +
              ")",
            value: this.props.location.state.ric,
            security_code: this.props.location.state.security_code
          };
        } else if (lastselection != null) {
          symbols.forEach((symbol) => {
            if ("ric" in symbol) {
              if (lastselection === symbol.ric) {
                selected = {
                  name: symbol.dsply_nmll,
                  ric: symbol.ric,
                  label:
                    symbol.dsply_nmll +
                    " (" +
                    symbol.security_code +
                    ")",
                  value: symbol.ric,
                  security_code: symbol.security_code
                };
              }
            }
          });
        } else {
          selected = {
            label: symbols[0].dsply_nmll + " (" + symbols[0].security_code + ")",
            value: symbols[0].ric,
            security_code: symbols[0].security_code,
            name: symbols[0].dsply_nmll,
            ric: symbols[0].ric,
          };
        }
      } else {
        symbols.forEach((symbol) => {
          if (this.ticker === symbol.security_code) {
            selected = {
              label: symbol.name + " (" + symbol.security_code + ")",
              value: symbol.ric,
              security_code: symbol.security_code,
              name: symbol.name,
              ric: symbol.ric,
            };
          }
        });
        if (selected == null) {
          selected = {
            label: symbols[0].name + " (" + symbols[0].security_code + ")",
            value: symbols[0].ric,
            security_code: symbols[0].security_code,
            name: symbols[0].name,
            ric: symbols[0].ric,
          };
        }
      }
    }
    if (selected == null) {
      selected = {
        label: symbols[0].dsply_nmll + " (" + symbols[0].security_code + ")",
        value: symbols[0].ric,
        security_code: symbols[0].security_code,
        name: symbols[0].dsply_nmll,
        ric: symbols[0].ric,
      };
    }
    return selected;
  }

  onFetchStaticSymbol() {
    let symbols = [];
    const staticData = MQSGUtil.getStaticData("WarrantCalculator");
    if ("symbols" in staticData) {
      if ("symbols" in staticData.symbols) {
        if (Array.isArray(staticData.symbols.symbols)) {
          symbols = staticData.symbols.symbols;
          this.setState({ symbols });
          const initselected = this.initSelection(symbols);
          if (initselected !== null) {
            this.onWarrantChange(initselected);
          }
          return;
        }
      }
    }
    this.setState({ symbols });
  }

  onFetchSymbol() {

    let symbols = [];
    const url = MQSGUtil.getAPIBasePath() + "/LiveMatrixJSON?ric=AEML_tm.SI&mode=0";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          if ("symbols" in result && Array.isArray(result.symbols)) {
            symbols = result.symbols;
          }
          this.setState({ symbols });
          const initselected = this.initSelection(symbols);
          if (initselected !== null) {
            this.onWarrantChange(initselected);
          }
        },
        (error) => {
          this.setState({ symbols });
        }
      );
  }

  onHandleData(ric, jsonData) {
    if (ric in jsonData) {
      return jsonData;
    }
    return null;
  }

  onFetchStaticData(ric) {
    const isLoading = false;
    const staticData = MQSGUtil.getStaticData("WarrantCalculator");
    if (ric in staticData) {
      const warrantdata = this.onHandleData(ric, staticData[ric]);
      this.setState({ warrantdata, isLoading });
    } else {
      this.setState({ warrantdata: null, isLoading });
    }
  }

  onFetchData(ric) {
    if (MQSGUtil.isUsingStaticData("WarrantCalculator")) {
      setTimeout(() => {
        this.onFetchStaticData(ric);
      }, 100); // pretend fetch
      return;
    }

    const isLoading = false;
    const url =
      MQSGUtil.getAPIBasePath() +
      "/MarketDataJSON?type=warrantdata&ric=" +
      ric +
      "&mode=0";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          const warrantdata = this.onHandleData(ric, result);
          this.setState({ warrantdata, isLoading });
        },
        (error) => {
          this.setState({ warrantdata: null, isLoading });
        }
      );
  }

  onWarrantChange(selected) {
    this.setState({ selected, isLoading: true, warrantdata: null });
    if (selected !== null) {
      this.onFetchData(selected.value);
      this.onFetchAllData(selected.value);
      const lastviewrics = MQSGUtil.updateLastView(selected.value);
      this.setState({ lastviewrics });
    }
  }

  getSymbols() {
    if (this.state.symbols !== null) {
      if (Array.isArray(this.state.symbols)) {
        return this.state.symbols;
      }
    }
    return null;
  }

  getWarrantData() {
    if (this.state.selected !== null) {
      const ric = this.state.selected.value;
      if (this.state.warrantdata !== null) {
        if (ric in this.state.warrantdata) {
          return this.state.warrantdata[ric];
        }
      }
    }
    return null;
  }
  getRightPanelData() {
    if (this.props.warrantdata?.ric_data) {
      return this.props.warrantdata.ric_data
    }
    // if (this.props.warrantdata !== null) {
    //   const ric = this.state.selected.value;
    //   if (this.state.warrantdata !== null) {
    //     if (ric in this.state.warrantdata) {
    //       return this.state.warrantdata[ric];
    //     }
    //   }
    // }
    return null;
  }
  isShowWrCalculatorBox() {
    if (this.state.isLoading || this.state.tabNum != 2) {
      return false;
    }
    return true;
  }

  renderRightPanel() {
    const warrantdata = this.getRightPanelData();
    if (this.state.tabNum == 2 && this.props.warrantdata != null) {
      return <WrCalculatorBox marketdata={warrantdata} isRightPanel={true} />;
    }
    return null;
  }
  childMethod() {
    if (this.refs.child) {
      this.refs.child.onClickTradeWarrantsHere()
    }
  }
  toThousands(str) {
    var newStr = "";
    var count = 0;
    var str = str + '';
    if (str.indexOf(".") == -1) {
      for (var i = str.length - 1; i >= 0; i--) {
        if (count % 3 == 0 && count != 0) {
          newStr = str.charAt(i) + "," + newStr;
        } else {
          newStr = str.charAt(i) + newStr;
        }
        count++;
      }
      // str = newStr + ".00"; //自动补小数点后两位
      str = newStr; //自动补小数点后两位
    }
    else {
      for (var i = str.indexOf(".") - 1; i >= 0; i--) {
        if (count % 3 == 0 && count != 0) {
          newStr = str.charAt(i) + "," + newStr;
        } else {
          newStr = str.charAt(i) + newStr; //逐个字符相接起来
        }
        count++;
      }
      str = newStr + (str + "00").substr((str + "00").indexOf("."), 3);
    }
    return str
  }
  setTab(i) {
    if (i != this.state.tabNum) {
      this.setState({
        tabNum: i
      })
    }
  }
  getTermHtml() {
    if (this.state.livematrixdata != null) {
      return (
        <div id="dwterms">
          <IndtableShow
            warrantdata={this.state.livematrixdata.ric_data}
          />
        </div>
      )
    }
  }
  renderMainPage() {
    const warrantdata = this.getWarrantData();
    const bid = MQSGUtil.getData(warrantdata, "BID");
    const ask = MQSGUtil.getData(warrantdata, "ASK");
    // const underlyingprice = MQSGUtil.getData(warrantdata, "underlying_price");
    const underlyingprice = MQSGUtil.getData(warrantdata, "underlying_bid");
    const label = MQSGUtil.getData(this.state.selected, "label");
    var isShow = false;
    if (this.state.tabNum == 2) {
      isShow = true
    } else {
      isShow = false
    }
    return (
      <div id="dwterms" className="pageobj" onClick={() => this.childMethod()}>
        <BreadcrumbBar
          link="/home"
          group="Warrant tools"
          title="Warrant calculator"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="section">
                <PageHeader title="Warrant calculator" ref="child" />
                <p>
                  Estimates the theoretical price of a warrant based on your
                  input of the underlying price, date or implied volatility.
                </p>
                <Underlying
                  underlying={this.state.underlying}
                  onUnderlyingChange={(option) =>
                    this.onUnderlyingChange(option)
                  }
                />
                <p style={{ margin: '15px 0px' }}></p>
                <Warrant
                  ref='selectWarrant'
                  symbols={this.state.symbols}
                  onWarrantChange={this.onWarrantChange.bind(this)}
                />
                {/* 导航 */}
                <div className="tablist">
                  <div id="topTabs" className="tablist-left" style={{ overflowX: 'auto' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td
                            className={classNames({
                              active: this.state.tabNum == 1,
                            })}
                            onClick={() => this.setTab(1)}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Historical Performance
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.tabNum == 4,
                            })}
                            onClick={() => this.setTab(4)}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Warrant terms
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.tabNum == 2,
                            })}
                            onClick={() => this.setTab(2)}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Warrant Calculator
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.tabNum == 3,
                            })}
                            onClick={() => this.setTab(3)}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Live matrix
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.tabNum == 5,
                            })}
                            onClick={() => this.setTab(5)}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Exposure simulator
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {this.isShowWrCalculatorBox() ? (
                  <WrCalculatorBox marketdata={warrantdata} />
                ) : null}
                <div style={{ display: this.state.tabNum == 1 ? 'block' : 'none' }}>
                  <TermsChart
                    selected={this.state.selected}
                    period={this.state.period}
                    chartdata={this.state.chartdata}
                    onPeriodChange={this.onPeriodChange.bind(this)}
                    warrantdata={warrantdata}
                  />
                </div>
                {
                  this.state.tabNum == 5 && warrantdata && <WarrantHedget
                    warrantdata={warrantdata}
                  />
                }
                {
                  // this.state.tabNum == 1
                  //   ? <TermsChart
                  //     selected={this.state.selected}
                  //     period={this.state.period}
                  //     chartdata={this.state.chartdata}
                  //     onPeriodChange={this.onPeriodChange.bind(this)}
                  //   />
                  //   : 
                  this.state.tabNum == 3 ? <LiveMatrixTodayTable selected={this.state.selected} tabNum={this.state.tabNum} logic={this.logic} isLoading={this.state.isLoading} date={this.state.livematrixdata?.last_update} /> : this.state.tabNum == 4 ? this.getTermHtml() : null
                }
              </div>
            </div>
            <div className="col-md-4 hidden-xs hidden-sm">
              <LastView lastviewrics={this.state.lastviewrics} />
              <LiveMatrix />
              <AdvertBox />
            </div>
          </div>
        </div>
      </div>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.ric !== this.props.ric) {
      if (this.state.symbols.length > 0) {
        const initselected = this.initSelection(
          this.state.symbols,
          nextProps.ric
        );
        this.onWarrantChange(initselected);
      }
    }
    return true;
  }

  render() {
    if (this.isRightPanel()) {
      return this.renderRightPanel();
    }
    return this.renderMainPage();
  }
}

class Warrant extends React.Component {
  // onChange(selected) {
  //   window.location.href = '/tools/warrantcalculator/' + selected.security_code

  // }
  onChange(selected) {

    window.history.replaceState(
      {},
      "",
      `/tools/warrantcalculator/${selected.security_code}`
    );
    this.props.onWarrantChange(selected);
  }

  getSymbol() {
    const options = [];
    if (this.props.symbols !== null && Array.isArray(this.props.symbols)) {
      this.props.symbols.forEach((symbol) => {
        if ("ric" in symbol && "dsply_nmll" in symbol && "security_code" in symbol) {
          const option = {
            label: symbol.dsply_nmll + " (" + symbol.security_code + ")",
            value: symbol.ric,
            security_code: symbol.security_code
          };
          options.push(option);
        }
      });
    }
    return options;
  }

  render() {
    return (
      <div style={{ margin: "20px 0 30px 0" }}>
        <Select
          placeholder="Select warrant"
          ref='warrant'
          options={this.getSymbol()}
          onChange={this.onChange.bind(this)}
          styles={{
            option: (provided, state) => ({
              ...provided,
              textAlign: "center",
              // backgroundColor: '#FFFFFF',
              // color: '#000',
              ':hover': {
                backgroundColor: '#1A73E8',
              }
            }),
            valueContainer: (provided) => ({
              ...provided,
              justifyContent: 'center'
            })
          }}
        />
      </div>
    );
  }
}
class TableGeneral extends Component {
  getData(fieldName, dp = null) {
    if (
      this.props.warrantdata !== null &&
      fieldName in this.props.warrantdata
    ) {
      const value = this.props.warrantdata[fieldName];
      if (dp !== null) {
        return Number(value).toFixed(dp);
      }
      return value;
    }
    return "";
  }

  getTheta() {
    // "last_trading_date": "23 Feb 21"
    const lasttradingdate = this.getData("last_trading_date");
    const currDate = new Date();
    const currDateMS = currDate.getTime();
    if (lasttradingdate.length > 0) {
      const lasttradingdateMS = Date.parse(lasttradingdate);
      const diff = currDateMS - lasttradingdateMS;
      // check if expired
      if (diff <= 24 * 60 * 60 * 1000) {
        return this.getData("theta", 4);
      }
    }
    return "-";
  }

  getMoneynessClass(nc) {
    let cnm = "";
    const value = Number(nc);
    if (!isNaN(value)) {
      if (value === 1) {
        cnm = "val mny up";
      } else if (value === 2) {
        cnm = "val mny down";
      }
    }
    return cnm;
  }

  getMoneynessName(nc) {
    let nm = "";
    const value = Number(nc);
    if (!isNaN(value)) {
      if (value === 1) {
        return "ITM";
      } else if (value === 2) {
        return "OTM";
      } else {
        return "ATM";
      }
    }
    return nm;
  }

  render() {
    const hstclose = this.getData("HST_CLOSE");
    const trdprc1 = this.getData("TRDPRC_1");
    const openprc = this.getData("OPEN_PRC");
    const acvol1scaled = this.getData("ACVOL_1_SCALED");
    const high1 = this.getData("HIGH_1");
    const turnoverscaled = this.getData("TURNOVER_SCALED");
    const low1 = this.getData("LOW_1");
    const underlyingprice = this.getData("underlying_price");
    const ticker = this.getData("ticker");
    const effectivegearing = this.getData("effective_gearing");
    const underlyingticker = this.getData("underlying_ticker");
    const delta = this.getData("delta");
    const type = this.getData("type");
    const deltaperwrnt = this.getData("delta_per_wrnt");
    const issuername = this.getData("issuer_name");
    const sensitivity = this.getData("sensitivity");
    const exerciseprice = this.getData("exercise_price");
    const breakevenprice = this.getData("breakeven_price");
    const tradedate = this.getData("TRADE_DATE");
    const premium = this.getData("premium");
    const maturity = this.getData("maturity");
    const impliedvolatility = this.getData("implied_volatility");
    const lasttradingdate = this.getData("last_trading_date");
    const theta = this.getTheta();
    const daystomaturity = this.getData("days_to_maturity") + " days";
    const intrinsicvalue = this.getData("intrinsic_value");
    const convratio = this.getData("conv_ratio");
    const moneyness = this.getData("moneyness");
    const moneynessClass = this.getMoneynessClass(moneyness);
    const moneynessdisplaystring =
      this.getData("percent_moneyness") +
      "% " +
      this.getMoneynessName(moneyness);

    return (
      <div>
        <div id="srtable" style={{ position: "relative" }}>
          <table className="table priorTable">
            <tbody>
              <tr>
                <th
                  className="srtable-leftlbl bgcolor-01"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Prior
                </th>
                <td
                  className="srtable-leftval bgcolor-01 val HST_CLOSE"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {hstclose}
                </td>
                <th
                  className="srtable-rightlbl bgcolor-02"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Last trade
                </th>
                <td
                  className="srtable-rightval bgcolor-02 val TRDPRC_1"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {trdprc1}
                </td>
              </tr>
              <tr>
                <th className="srtable-leftlbl" style={{ border: 0, textAlign: 'left' }}>
                  Open
                </th>
                <td
                  className="srtable-leftval val OPEN_PRC"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {openprc}
                </td>
                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                  Traded volume ('000)
                </th>
                <td className="srtable-rightval val ACVOL_1_SCALED" style={{ textAlign: 'left' }}>
                  {acvol1scaled}
                </td>
              </tr>
              <tr>
                <th
                  className="srtable-leftlbl bgcolor-01"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  High
                </th>
                <td
                  className="srtable-leftval bgcolor-01 val HIGH_1"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {high1}
                </td>
                <th
                  className="srtable-rightlbl bgcolor-02"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Traded value ('000 SGD)
                </th>
                <td
                  className="srtable-rightval bgcolor-02 val TURNOVER_SCALED"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {turnoverscaled}
                </td>
              </tr>
              <tr>
                <th className="srtable-leftlbl" style={{ textAlign: 'left' }}>Low</th>
                <td className="srtable-leftval val LOW_1" style={{ border: 0, textAlign: 'left' }}>
                  {low1}
                </td>
                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                  Underlying price
                </th>
                <td
                  className="srtable-rightval val underlying_price"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {underlyingprice}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
class Underlying extends React.Component {
  constructor(props) {
    super(props);
    this.underlyingOption = null;
  }

  onChange(selected) {
    this.props.onUnderlyingChange(selected);
  }
  getUnderlyingOption() {
    if (this.underlyingOption === null) {
      if (this.props.underlying !== null) {
        if ("list" in this.props.underlying) {
          const symbolData = this.props.underlying.list;

          if (Array.isArray(symbolData)) {
            this.underlyingOption = [];
            symbolData.forEach((data) => {
              if ("underlying_ric" in data) {
                const displayname = MQSGUtil.getUnderlyingTickerDisplayName(
                  data.underlying_ric,
                  data.underlying_ticker,
                  false
                );
                if (displayname !== null) {
                  data["label"] = displayname;
                  data["value"] = data.underlying_ticker;
                  this.underlyingOption.push(data);
                }
              }
            });
          }
        }
      }
    }
    return this.underlyingOption;
  }
  filterOption(candidate, input) {
    var value = this.stripscript(input).toUpperCase();
    var labelValue = this.stripscript(candidate.label).toUpperCase()
    if (labelValue.indexOf(value) != -1) return true;
    return createFilter()(candidate, input);
  }
  stripscript(s) {
    var pattern = new RegExp(/[`~!@#$^\-&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g);
    var rs = "";
    for (var i = 0; i < s.length; i++) {
      rs = rs + s.substr(i, 1).replace(pattern, '');
    }
    return rs;
  }

  render() {
    const customStyles = {
      menu: (provided) => ({
        ...provided,
        zIndex: 10,
        textAlign: "center",
      }),
      option: (provided, state) => ({
        ...provided,
        color: '#000',
        backgroundColor: '#FFFFFF',
        ':hover': {
          backgroundColor: '#1A73E8',
        },
      }),
      valueContainer: (provided) => ({
        ...provided,
        justifyContent: 'center'
      })
    };

    return (
      <div id="dropdown-underlying">
        <div>
          <Select
            placeholder="Select underlying"
            options={this.getUnderlyingOption()}
            styles={customStyles}
            onChange={this.onChange.bind(this)}
            filterOption={this.filterOption.bind(this)}
          />
        </div>
      </div>
    );
  }
}
export default WarrantCalculator;

// 1. enforce selection
// 2. no matching ric/symobl found > no data display
