import React, { Component } from "react";
import classNames from "classnames";
import Select, { createFilter } from "react-select";
import ReactTooltip from "react-tooltip";
import PageHeader from "../../../components/pageHeader/pageHeader";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import MQSGUtil from "../../../components/mqsgUtil";
import AdvertBox from "../../../components/advert/advert";
import "./term.css";
import highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import nodata from "highcharts/modules/no-data-to-display";
nodata(highcharts);
import DatePicker from "react-datepicker";
import { LiveMatrixTodayTable, IndtableShow, TermsChart, WrCalculatorBox, LivematrixLogic, WarrantHedget } from "../../../components/WarrantTools";
export default class Warrantterms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticker: null,
      tabNum: 4,
      selected: {
        period: "Y",
        underlyingric: null,
        underlyingcurr: "",
        ric: null,
        symbol: null,
        lastupdated: null,
      },
      symbolsdata: null,
      warrantdata: null,
      chartdata: null,
      isLoading: true,
      isLoadingSymbol: true,
      isLoadingChart: true,
      isLoadingWarrant: true,
      livematrixdata: null,
      underlying: [],
      title: ''

    };
    this.logic = null;
    this.initRedirect(props, this.state);
  }

  componentDidMount() {
    this.onFetchSymbol();
    this.onFetchUnderlyingSymbol()
    if (window.location.href.indexOf('formance') != -1) {
      this.setState({ tabNum: 1, title: 'Historical Performance' });
    } else if (window.location.href.indexOf('exposure') != -1) {
      this.setState({ tabNum: 5, title: 'Exposure simulator' });
    } else {
      this.setState({ tabNum: 4, title: 'Warrant terms' });
    }
    document.title = "Warrant Terms | Singapore Warrants | Macquarie"
  }
  onFetchData(ric) {
    const isLoading = false;
    const url =
      MQSGUtil.getAPIBasePath() + "/LiveMatrixJSON?ric=" + ric + "&mode=0";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          const livematrixdata = result;
          this.logic = new LivematrixLogic(
            livematrixdata.livematrix,
            livematrixdata.ric_data
          );
          this.setState({ livematrixdata, isLoading }, () => {
          });
        },
        (error) => {
          this.setState({ livematrixdata: null, isLoading });
        }
      );
  }
  initRedirect(props, state) {
    const match = MQSGUtil.getData(props, "match", null);
    if (match !== null) {
      const params = MQSGUtil.getData(match, "params", null);
      if (params !== null) {
        const ticker = MQSGUtil.getData(params, "ticker", null);
        if (ticker !== null) {
          state["ticker"] = ticker.toUpperCase();
        }
      }
    }
  }

  onFetchStaticSymbol() {
    const isLoadingSymbol = false;
    const staticData = MQSGUtil.getStaticData("Warrantterms");
    this.setState({ symbolsdata: staticData.symbols, isLoadingSymbol });
    const symbol = { value: "AEML_tk.SI", label: "AEM MB eCW210302 (983W)" };
    this.onWarrantChange(symbol);
  }

  onFetchSymbol() {
    if (MQSGUtil.isUsingStaticData("Warrantterms")) {
      this.onFetchStaticSymbol();
      return;
    }
    const isLoadingSymbol = false;
    let symbolsdata = null;
    const url = MQSGUtil.getAPIBasePath() + "/LiveMatrixJSON?ric=AEML_tm.SI&mode=0";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          if ("symbols" in result && Array.isArray(result.symbols)) {
            symbolsdata = result.symbols;
            const initSymbol = this.initSymbol(symbolsdata);
            this.onWarrantChange(initSymbol);
          }
          this.setState({ symbolsdata, isLoadingSymbol });
        },
        (error) => {
          this.setState({ symbolsdata, isLoadingSymbol });
        }
      );
  }

  onFetchStaticChartData(underlyingric, ric, period = "D") {
    const isLoadingChart = false;
    const staticData = MQSGUtil.getStaticData("Warrantterms");
    let chartdata = null;
    if (ric in staticData) {
      const termdataAll = staticData[ric];
      if (period in termdataAll) {
        chartdata = termdataAll[period];
      }
    }
    this.setState({ chartdata, isLoadingChart });
  }

  onFetchChartData(underlyingRic, ric, period = "D") {
    if (MQSGUtil.isUsingStaticData("Warrantterms")) {
      setTimeout(() => {
        this.onFetchStaticChartData(underlyingRic, ric, period);
      }, 100); // pretend fetch
      return;
    }
    const isLoadingChart = false;
    const ricString = underlyingRic + ";" + ric;
    let chartdata = null;
    const url =
      MQSGUtil.getAPIBasePath() +
      "/alert?TYPE=JSON&RIC=" +
      ricString +
      "&PERIODICITY=" +
      period +
      "&d=" +
      Date.now();
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          chartdata = result;
          this.setState({ chartdata, isLoadingChart });
        },
        (error) => {
          this.setState({ chartdata, isLoadingChart });
        }
      );
  }

  onFetchStaticWarrantData(ric) {
    const isLoadingWarrant = false;
    const staticData = MQSGUtil.getStaticData("Warrantterms");
    let warrantdata = null;
    if (ric in staticData) {
      warrantdata = staticData[ric];
      const underlyingric = this.getUnderlyingRic(ric, warrantdata);
      if (underlyingric !== null) {
        const selectedNew = { ...this.state.selected };
        selectedNew["underlyingric"] = underlyingric;
        selectedNew["lastupdated"] = this.getLastupdated(warrantdata);
        const underlyingcurr = this.getData(
          "underlying_curr",
          warrantdata[ric]
        );
        if (underlyingcurr !== null) {
          selectedNew["underlyingcurr"] = underlyingcurr;
        }
        this.setState({ selected: selectedNew, warrantdata, isLoadingWarrant });
        this.onFetchStaticChartData(
          underlyingric,
          ric,
          this.state.selected.period
        );
      }
    }
  }

  onFetchWarrantData(ric) {
    if (MQSGUtil.isUsingStaticData("Warrantterms")) {
      setTimeout(() => {
        this.onFetchStaticWarrantData(ric);
      }, 100); // pretend fetch
      return;
    }
    const isLoadingWarrant = false;
    let warrantdata = null;
    const url =
      MQSGUtil.getAPIBasePath() + "/MarketDataJSON?type=warrantdata&ric=" + ric;
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          warrantdata = result;
          const underlyingric = this.getUnderlyingRic(ric, warrantdata);
          if (underlyingric !== null) {
            const selectedNew = { ...this.state.selected };
            selectedNew["underlyingric"] = underlyingric;
            selectedNew["lastupdated"] = this.getLastupdated(warrantdata);
            const underlyingcurr = this.getData(
              "underlying_curr",
              warrantdata[ric]
            );
            if (underlyingcurr !== null) {
              selectedNew["underlyingcurr"] = underlyingcurr;
            }
            this.setState({
              selected: selectedNew,
              warrantdata,
              isLoadingWarrant,
            });
            this.onFetchChartData(
              underlyingric,
              ric,
              this.state.selected.period
            );
          }
        },
        (error) => {
          this.setState({ warrantdata, isLoadingWarrant });
        }
      );
  }

  initSymbol(symbolsdata) {
    const symbols = symbolsdata;
    let lastselection = MQSGUtil.getLastSelection(this.page);
    let selected = null;
    if (symbols.length > 0) {
      if (this.state.ticker === null) {
        if (this.props.location.state !== undefined) {
          selected = {
            label:
              this.props.location.state.dsply_nmll +
              " (" +
              this.props.location.state.security_code +
              ")",
            value: this.props.location.state.ric,
          };
        } else if (lastselection != null) {
          symbols.forEach((symbol) => {
            if ("ric" in symbol) {
              if (lastselection === symbol.ric) {
                selected = {
                  label: symbol.dsply_nmll + " (" + symbol.security_code + ")",
                  value: symbol.ric,
                };
              }
            }
          });
        } else {
          selected = {
            label: symbols[0].dsply_nmll + " (" + symbols[0].security_code + ")",
            value: symbols[0].ric,
          };
        }
      } else {
        symbols.forEach((symbol) => {
          if (this.state.ticker === symbol.security_code) {
            selected = {
              label: symbol.dsply_nmll + " (" + symbol.security_code + ")",
              value: symbol.ric,
            };
          }
        });
        if (selected == null) {
          selected = {
            label: symbols[0].dsply_nmll + " (" + symbols[0].security_code + ")",
            value: symbols[0].ric,
          };
        }
      }
    }
    if (selected == null) {
      selected = {
        label: symbols[0].dsply_nmll + " (" + symbols[0].security_code + ")",
        value: symbols[0].ric,
      };
    }
    return selected;
  }

  getData(fieldName, data) {
    if (fieldName in data) {
      return data[fieldName];
    }
    return null;
  }

  getSymbols() {
    if (this.state.symbolsdata !== null) {
      if (Array.isArray(this.state.symbolsdata)) {
        return this.state.symbolsdata;
      }
    }
    return [];
  }
  getWarrantData(ric) {
    if (this.state.warrantdata !== null) {
      if (ric in this.state.warrantdata) {
        return this.state.warrantdata[ric];
      }
    }
    return null;
  }

  getLastupdated(warrantdata) {
    if ("systemtime" in warrantdata) {
      return warrantdata["systemtime"];
    }
    return "";
  }

  getUnderlyingRic(ric, warrantdata) {
    if (warrantdata !== null) {
      if (ric in warrantdata) {
        const underlyingric = this.getData("underlying_ric", warrantdata[ric]);
        if (underlyingric !== null) {
          return underlyingric;
        }
      }
    }
    return null;
  }

  onWarrantChange(symbol) {
    const selectedNew = { ...this.state.selected };
    selectedNew["symbol"] = symbol;
    selectedNew["ric"] = symbol.value;
    this.setState({
      selected: selectedNew,
      isLoadingWarrant: true,
      warrantdata: null,
      livematrixdata: null,
    });
    MQSGUtil.updateLastView(symbol.value);
    this.onFetchWarrantData(symbol.value);
    this.onFetchData(symbol.value);
  }

  onPeriodChange(period) {
    if (this.state.warrantdata !== null) {
      const ric = this.state.selected.ric;
      const underlyingric = this.state.selected.underlyingric;
      if (underlyingric !== null) {
        const selectedNew = { ...this.state.selected };
        selectedNew["period"] = period;
        this.setState({ selected: selectedNew, chartdata: null });
        this.onFetchChartData(underlyingric, ric, period);
      }
    }
  }
  setTab(i) {
    if (i != this.state.tabNum) {

      this.setState({
        tabNum: i
      })
    }
  }
  childMethod() {
    if (this.refs.child) {
      this.refs.child.onClickTradeWarrantsHere()
    }
  }
  onFetchUnderlyingSymbol() {
    const url = MQSGUtil.getAPIBasePath() + "/UnderlyingRankingJSON?mode=2";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ underlying: result });
        },
        (error) => {
          this.setState({ underlying: [] });
        }
      );
  }
  onUnderlyingChange(option) {
    const url = MQSGUtil.getAPIBasePath() + "/SymbolsJSON?ric=" + option.underlying_ric;
    this.refs.selectWarrant.refs.warrant.onMenuOpen()
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ symbolsdata: result });
        },
      );
  }
  render() {
    let ric = null;
    if (
      typeof this.state.selected !== "undefined" &&
      this.state.selected !== null
    ) {
      if ("ric" in this.state.selected) {
        ric = this.state.selected.ric;
      }
    }
    var isShow = false;
    if (this.state.tabNum == 2) {
      isShow = true
    } else {
      isShow = false
    }
    return (
      <div id="dwterms" className="pageobj" onClick={() => this.childMethod()}>
        <BreadcrumbBar
          link="/home"
          group="Warrant tools"
          title={this.state.title}
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="section">
                <PageHeader title={this.state.title} ref="child" />
                <p>
                  All the key parameters and terms about your selected warrants
                  are found here.
                </p>
                <Underlying
                  underlying={this.state.underlying}
                  onUnderlyingChange={(option) =>
                    this.onUnderlyingChange(option)
                  }
                />
                <p style={{ margin: '15px 0px' }}></p>
                <Warrant
                  ref='selectWarrant'
                  symbols={this.getSymbols()}
                  onWarrantChange={(selected) => this.onWarrantChange(selected)}
                />
                {/* 导航 */}
                <div className="tablist">
                  <div id="topTabs" className="tablist-left" style={{ overflowX: 'auto' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td
                            className={classNames({
                              active: this.state.tabNum == 1,
                            })}
                            onClick={() => this.setTab(1)}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Historical Performance
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.tabNum == 4,
                            })}
                            onClick={() => this.setTab(4)}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Warrant terms
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.tabNum == 2,
                            })}
                            onClick={() => this.setTab(2)}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Warrant Calculator
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.tabNum == 3,
                            })}
                            onClick={() => this.setTab(3)}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Live matrix
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames({
                              active: this.state.tabNum == 5,
                            })}
                            onClick={() => this.setTab(5)}
                          >
                            <div className="tablist-item">
                              <div className="tablist-item-txt">
                                Exposure simulator
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div style={{ display: this.state.tabNum == 1 ? 'block' : 'none' }}>
                  <TermsChart
                    selected={this.state.selected}
                    period={this.state.period}
                    chartdata={this.state.chartdata}
                    onPeriodChange={this.onPeriodChange.bind(this)}
                    warrantdata={this.getWarrantData(this.state.selected.ric)}
                  />
                </div>
                {
                  this.state.tabNum == 3 ? <LiveMatrixTodayTable date={this.state.livematrixdata?.last_update} selected={this.state.selected.symbol} tabNum={this.state.tabNum} logic={this.logic} isLoading={this.state.isLoading} /> : this.state.tabNum == 4 ? <IndtableShow
                    warrantdata={this.getWarrantData(this.state.selected.ric)}
                  /> : null
                }
                {
                  isShow && <WrCalculatorBox isRightPanel={true} ric={ric} marketdata={this.state.livematrixdata && this.state.livematrixdata.ric_data} />
                }
                {
                  this.state.tabNum == 5 && this.getWarrantData(this.state.selected.ric) && <WarrantHedget
                    warrantdata={this.getWarrantData(this.state.selected.ric)}
                  />
                }


              </div>
            </div>
            <div className="col-md-4 hidden-xs hidden-sm">
              <AdvertBox />
              {/* <WarrantCalculator isRightPanel={true} ric={ric} /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Warrant extends React.Component {
  onChange(selected) {
    // this.props.onWarrantChange(selected);
    // window.location.href = '/tools/warrantterms/' + selected.security_code
    var name = ''
    if (window.location.href.indexOf('formance') != -1) {
      name = '/tools/HistoricalPerformance/'
    } else if (window.location.href.indexOf('mulator') != -1) {
      name = '/tools/exposuresimulator/'
    } else {
      name = '/tools/warrantterms/'
    }
    window.history.replaceState(
      {},
      "",
      `${name}${selected.security_code}`
    );
    this.props.onWarrantChange(selected);
  }

  getSymbol() {
    const options = [];
    if (this.props.symbols !== null && Array.isArray(this.props.symbols)) {
      this.props.symbols.forEach((symbol) => {
        if ("ric" in symbol && "dsply_nmll" in symbol && "security_code" in symbol) {
          const option = {
            label: symbol.dsply_nmll + " (" + symbol.security_code + ")",
            value: symbol.ric,
            security_code: symbol.security_code
          };
          options.push(option);
        }
      });
    }
    return options;
  }

  render() {
    const style = {}; //{margin:'20px 0 30px 0'}
    return (
      <div style={style}>
        <Select
          placeholder="Select warrant"
          options={this.getSymbol()}
          ref='warrant'
          onChange={(selected) => this.onChange(selected)}
          styles={{
            option: (provided, state) => ({
              ...provided,
              textAlign: "center",
              // backgroundColor: '#FFFFFF',
              // color: '#000',
              ':hover': {
                backgroundColor: '#1A73E8',
              },
            }),

            valueContainer: (provided) => ({
              ...provided,
              justifyContent: 'center'
            })
          }}
        />
      </div>
    );
  }
}
class Underlying extends React.Component {
  constructor(props) {
    super(props);
    this.underlyingOption = null;
  }

  onChange(selected) {
    this.props.onUnderlyingChange(selected);
  }
  getUnderlyingOption() {
    if (this.underlyingOption === null) {
      if (this.props.underlying !== null) {
        if ("list" in this.props.underlying) {
          const symbolData = this.props.underlying.list;

          if (Array.isArray(symbolData)) {
            this.underlyingOption = [];
            symbolData.forEach((data) => {
              if ("underlying_ric" in data) {
                const displayname = MQSGUtil.getUnderlyingTickerDisplayName(
                  data.underlying_ric,
                  data.underlying_ticker,
                  false
                );
                if (displayname !== null) {
                  data["label"] = displayname;
                  data["value"] = data.underlying_ticker;
                  this.underlyingOption.push(data);
                }
              }
            });
          }
        }
      }
    }
    return this.underlyingOption;
  }
  filterOption(candidate, input) {
    var value = this.stripscript(input).toUpperCase();
    var labelValue = this.stripscript(candidate.label).toUpperCase()
    if (labelValue.indexOf(value) != -1) return true;
    return createFilter()(candidate, input);
  }
  stripscript(s) {
    var pattern = new RegExp(/[`~!@#$^\-&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g);
    var rs = "";
    for (var i = 0; i < s.length; i++) {
      rs = rs + s.substr(i, 1).replace(pattern, '');
    }
    return rs;
  }

  render() {
    const customStyles = {
      menu: (provided) => ({
        ...provided,
        zIndex: 10,
        textAlign: "center",
      }),
      option: (provided, state) => ({
        ...provided,
        color: '#000',
        backgroundColor: '#FFFFFF',
        ':hover': {
          backgroundColor: '#1A73E8',
        },
      }),
      valueContainer: (provided) => ({
        ...provided,
        justifyContent: 'center'
      })
    };

    return (
      <div id="dropdown-underlying">
        <div>
          <Select
            placeholder="Select underlying"
            options={this.getUnderlyingOption()}
            styles={customStyles}
            onChange={this.onChange.bind(this)}
            filterOption={this.filterOption.bind(this)}
          />
        </div>
      </div>
    );
  }
}
class TableGeneral extends Component {
  getData(fieldName, dp = null) {
    if (
      this.props.warrantdata !== null &&
      fieldName in this.props.warrantdata
    ) {
      const value = this.props.warrantdata[fieldName];
      if (dp !== null) {
        return Number(value).toFixed(dp);
      }
      return value;
    }
    return "";
  }

  getTheta() {
    // "last_trading_date": "23 Feb 21"
    const lasttradingdate = this.getData("last_trading_date");
    const currDate = new Date();
    const currDateMS = currDate.getTime();
    if (lasttradingdate.length > 0) {
      const lasttradingdateMS = Date.parse(lasttradingdate);
      const diff = currDateMS - lasttradingdateMS;
      // check if expired
      if (diff <= 24 * 60 * 60 * 1000) {
        return this.getData("theta", 4);
      }
    }
    return "-";
  }

  getMoneynessClass(nc) {
    let cnm = "";
    const value = Number(nc);
    if (!isNaN(value)) {
      if (value === 1) {
        cnm = "val mny up";
      } else if (value === 2) {
        cnm = "val mny down";
      }
    }
    return cnm;
  }

  getMoneynessName(nc) {
    let nm = "";
    const value = Number(nc);
    if (!isNaN(value)) {
      if (value === 1) {
        return "ITM";
      } else if (value === 2) {
        return "OTM";
      } else {
        return "ATM";
      }
    }
    return nm;
  }

  render() {
    const hstclose = this.getData("HST_CLOSE");
    const trdprc1 = this.getData("TRDPRC_1");
    const openprc = this.getData("OPEN_PRC");
    const acvol1scaled = this.getData("ACVOL_1_SCALED");
    const high1 = this.getData("HIGH_1");
    const turnoverscaled = this.getData("TURNOVER_SCALED");
    const low1 = this.getData("LOW_1");
    const underlyingprice = this.getData("underlying_price");
    const ticker = this.getData("ticker");
    const effectivegearing = this.getData("effective_gearing");
    const underlyingticker = this.getData("underlying_ticker");
    const delta = this.getData("delta");
    const type = this.getData("type");
    const deltaperwrnt = this.getData("delta_per_wrnt");
    const issuername = this.getData("issuer_name");
    const sensitivity = this.getData("sensitivity");
    const exerciseprice = this.getData("exercise_price");
    const breakevenprice = this.getData("breakeven_price");
    const tradedate = this.getData("TRADE_DATE");
    const premium = this.getData("premium");
    const maturity = this.getData("maturity");
    const impliedvolatility = this.getData("implied_volatility");
    const lasttradingdate = this.getData("last_trading_date");
    const theta = this.getTheta();
    const daystomaturity = this.getData("days_to_maturity") + " days";
    const intrinsicvalue = this.getData("intrinsic_value");
    const convratio = this.getData("conv_ratio");
    const moneyness = this.getData("moneyness");
    const moneynessClass = this.getMoneynessClass(moneyness);
    const moneynessdisplaystring =
      this.getData("percent_moneyness") +
      "% " +
      this.getMoneynessName(moneyness);

    return (
      <div>
        <div id="srtable" style={{ position: "relative" }}>
          <table className="table priorTable">
            <tbody>
              <tr>
                <th
                  className="srtable-leftlbl bgcolor-01"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Prior
                </th>
                <td
                  className="srtable-leftval bgcolor-01 val HST_CLOSE"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {hstclose}
                </td>
                <th
                  className="srtable-rightlbl bgcolor-02"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Last trade
                </th>
                <td
                  className="srtable-rightval bgcolor-02 val TRDPRC_1"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {trdprc1}
                </td>
              </tr>
              <tr>
                <th className="srtable-leftlbl" style={{ border: 0, textAlign: 'left' }}>
                  Open
                </th>
                <td
                  className="srtable-leftval val OPEN_PRC"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {openprc}
                </td>
                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                  Traded volume ('000)
                </th>
                <td className="srtable-rightval val ACVOL_1_SCALED" style={{ textAlign: 'left' }}>
                  {acvol1scaled}
                </td>
              </tr>
              <tr>
                <th
                  className="srtable-leftlbl bgcolor-01"
                  style={{ border: 0, textAlign: 'left', textAlign: 'left' }}
                >
                  High
                </th>
                <td
                  className="srtable-leftval bgcolor-01 val HIGH_1"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {high1}
                </td>
                <th
                  className="srtable-rightlbl bgcolor-02"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Traded value ('000 SGD)
                </th>
                <td
                  className="srtable-rightval bgcolor-02 val TURNOVER_SCALED"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {turnoverscaled}
                </td>
              </tr>
              <tr>
                <th className="srtable-leftlbl" style={{ textAlign: 'left' }}>Low</th>
                <td className="srtable-leftval val LOW_1" style={{ border: 0, textAlign: 'left' }}>
                  {low1}
                </td>
                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                  Underlying price
                </th>
                <td
                  className="srtable-rightval val underlying_price"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {underlyingprice}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
