import React, {Component} from "react";
import Select, {createFilter} from "react-select";
import classNames from "classnames";
import PageHeader from "../../../components/pageHeader/pageHeader";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import MQSGUtil from "../../../components/mqsgUtil";
// import WarrantCalculator from "../warrantcalculator/calculator";
import AdvertBox from "../../../components/advert/advert";
import ReactTooltip from "react-tooltip";
import {Link} from "react-router-dom";
import "./livematrix.css";
import LiveIndexFuture from "../../../components/liveindexfuture/liveindexfuture";
import "../warrantterm/term.css";

import {
  IndtableShow,
  LivematrixLogic,
  LiveMatrixTodayTable,
  TermsChart,
  WarrantHedget,
  WrCalculatorBox
} from "../../../components/WarrantTools";
import LastView from "../../../components/lastView";

export default class LiveMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      symbols: null,
      livematrixdata: null,
      isLoading: true,
      tabNum: 3,
      period: 'Y',
      chartData: [],
      isLoadingChart: false,
      underlying: [],
      lastviewrics: null,
      date1: '',
      latestNewTitle: "",
      latestNewUnderlying: "",
      showHSILiveIndex: false,
      showNKYLiveIndex: false,
      showHSTECHLiveIndex: false,
      showSIMSCILiveIndex: false,
      showSP500LiveIndex: false,
      displayMorningMarketBuzz: false,
    };
    this.page = "livematrix";
    this.logic = null;
    this.ticker = null;
    this.initRedirect(props);
  }
  onFetchUnderlyingSymbol() {
    const url = MQSGUtil.getAPIBasePath() + "/UnderlyingRankingJSON?mode=2";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ underlying: result });
        },
        (error) => {
          this.setState({ underlying: [] });
        }
      );
  }
  onUnderlyingChange(option) {
    const url = MQSGUtil.getAPIBasePath() + "/SymbolsJSON?ric=" + option.underlying_ric;
    this.refs.selectWarrant.refs.warrant.onMenuOpen()
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ symbols: result });
        },
      );
  }
  onPeriodChange(period) {
    if (this.state.livematrixdata !== null) {
      const ric = this.state.selected.value;
      const underlyingric = this.state.livematrixdata.ric_data.underlying_ric;
      if (underlyingric !== null) {
        // const selectedNew = { ...this.state.selected };
        this.setState({ period: period, chartdata: null });
        this.onFetchChartData(underlyingric, ric, period);
      }
    }
  }
  onFetchChartData(underlyingRic, ric, period = "Y") {
    if (MQSGUtil.isUsingStaticData("Warrantterms")) {
      setTimeout(() => {
        this.onFetchStaticChartData(underlyingRic, ric, period);
      }, 100); // pretend fetch
      return;
    }
    const isLoadingChart = false;
    const ricString = underlyingRic + ";" + ric;
    let chartdata = null;
    const url =
      MQSGUtil.getAPIBasePath() +
      "/alert?TYPE=JSON&RIC=" +
      ricString +
      "&PERIODICITY=" +
      period +
      "&d=" +
      Date.now();
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          chartdata = result;
          this.setState({ chartdata, isLoadingChart });
        },
        (error) => {
          this.setState({ chartdata, isLoadingChart });
        }
      );
  }
  componentDidMount() {
    if (!this.isHome()) {
      document.title = "Live matrix | Singapore Warrants | Macquarie"
      this.onFetchUnderlyingSymbol()
      if (this.props.match.params.ticker) {
        let symbols = null;
        const url = MQSGUtil.getAPIBasePath() + "/LiveMatrixJSON?ric=AEML_tm.SI&mode=0";
        fetch(url)
          .then((res) => res.json())
          .then((result) => {
              if ("symbols" in result) {
                symbols = result.symbols;
                this.setState({ symbols, tabNum: 3 });
                let check = symbols.filter((item) => {
                  if (item['security_code'] == this.props.match.params.ticker) {
                    item.label = item.dsply_nmll + " (" + item.security_code + ")";
                    item.value = item.ric;
                    return item
                  }
                })
                if (check.length > 0) {
                  var initselected = { label: check[0].label, value: check[0].value, future_dsply_name: check[0].future_dsply_name }
                  this.onWarrantChange(initselected);
                } else {
                  var initselected = this.initSelection(symbols);
                  this.onWarrantChange(initselected);
                }
              }
            },
            (error) => {
              this.setState({ symbols, isLoading: false });
            }
          );
      } else {
        this.onFetchSymbol();
      }
    } else {
      this.onFetchSymbol();
    }
  }
  setTab(i) {
    if (i != this.state.tabNum) {
      this.setState({
        tabNum: i
      })
    }
  }
  initRedirect(props) {
    const match = MQSGUtil.getData(props, "match", null);
    if (match !== null) {
      const params = MQSGUtil.getData(match, "params", null);
      if (params !== null) {
        const ticker = MQSGUtil.getData(params, "ticker", null);
        if (ticker !== null) {
          this.ticker = ticker.toUpperCase();
        }
      }
    }
  }

  initSelection(symbols) {
    let lastselection = MQSGUtil.getLastSelection(this.page);
    if (this.ticker !== null) {
      lastselection = null;
    }
    let selectedsymbol = null;
    let initsymbol = null;
    if (symbols !== null && Array.isArray(symbols)) {
      symbols.forEach((symbol) => {
        if ("ric" in symbol) {
          if (initsymbol === null) {
            initsymbol = symbol;
          }
          if (lastselection === symbol.ric) {
            selectedsymbol = symbol;
          }
        }
        if ("security_code" in symbol) {
          if (this.ticker === symbol["security_code"]) {
            selectedsymbol = symbol;
          }
        }
      });
    }
    if (selectedsymbol !== null) {
      initsymbol = selectedsymbol;
    }
    if (initsymbol !== null) {
      if ("dsply_nmll" in initsymbol && "security_code" in initsymbol) {
        return {
          label: initsymbol.dsply_nmll + " (" + initsymbol.security_code + ")",
          value: initsymbol.ric,
          future_dsply_name: initsymbol.future_dsply_name
        };
      }
    }
    this.ticker = null;
    return null;
  }

  onFetchStaticSymbol() {
    let symbols = null;
    const staticData = MQSGUtil.getStaticData("LiveMatrix");
    if ("symbols" in staticData) {
      symbols = staticData.symbols;
      this.setState({ symbols });
      const initselected = this.initSelection(symbols);
      this.onWarrantChange(initselected);
    } else {
      this.setState({ symbols, isLoading: false });
    }
  }

  onFetchSymbol() {
    if (MQSGUtil.isUsingStaticData("LiveMatrix")) {
      this.onFetchStaticSymbol();
      return;
    }

    let symbols = null;
    const url =
      MQSGUtil.getAPIBasePath() + "/LiveMatrixJSON?ric=AEML_tm.SI&mode=0";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          if ("symbols" in result) {
            symbols = result.symbols;
            this.setState({ symbols });
            const initselected = this.initSelection(symbols);
            this.onWarrantChange(initselected);
          }
        },
        (error) => {
          this.setState({ symbols, isLoading: false });
        }
      );
  }

  onFetchStaticData(ric) {
    const isLoading = false;
    const staticData = MQSGUtil.getStaticData("LiveMatrix");
    if (ric in staticData) {
      let livematrixdata = staticData[ric];
      this.logic = new LivematrixLogic(
        livematrixdata.livematrix,
        livematrixdata.ric_data
      );
      this.setState({ livematrixdata, isLoading });
    } else {
      this.setState({ livematrixdata: null, isLoading });
    }
  }

  onFetchData(ric) {
    if (MQSGUtil.isUsingStaticData("LiveMatrix")) {
      setTimeout(() => {
        this.onFetchStaticData(ric);
      }, 100); // pretend fetch
      return;
    }
    const isLoading = false;
    const url = MQSGUtil.getAPIBasePath() + "/LiveMatrixJSON?ric=" + ric + "&mode=0";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          const livematrixdata = result;
          this.logic = new LivematrixLogic(
            livematrixdata.livematrix,
            livematrixdata.ric_data
          );
          this.setState({ livematrixdata, isLoading }, () => {
            const underlyingric = this.state.livematrixdata.ric_data.underlying_ric;
            const underlying_ticker = this.state.livematrixdata.ric_data.underlying_ticker;
            this.onFetchChartData(
              underlyingric,
              ric,
              this.state.period
            );
            if (underlying_ticker === "HSI" || underlying_ticker === "N225" || underlying_ticker === "HSTECH" || underlying_ticker === "SIMSCI" || underlying_ticker === "S&P500") {
              this.refreshIndex(underlying_ticker)
            } else {
              this.setState({showHSILiveIndex: false, showNKYLiveIndex: false, showHSTECHLiveIndex: false, showSIMSCILiveIndex: false, showSP500LiveIndex: false})
            }
            if (underlying_ticker && underlying_ticker.length > 0) {
              this.setState({displayMorningMarketBuzz: true})
              this.getMorningMarketBuzzTile(underlying_ticker)
            }
          });
        },
        (error) => {
          this.setState({ livematrixdata: null, isLoading });
        }
      );
  }

  onWarrantChange(selected) {
    if (selected !== undefined && selected !== null) {
      MQSGUtil.updateLastView(selected.value);
      this.logic = null; // hide previous data
      this.setState({ selected, livematrixdata: null, isLoading: true });
      this.onFetchData(selected.value);
      MQSGUtil.updateLastSelection(this.page, selected.value);
      const lastviewrics = MQSGUtil.updateLastView(selected.value);
      this.setState({ lastviewrics });
    }
  }

  onRefresh() {
    this.onWarrantChange(this.state.selected);
  }

  getSymbols() {
    if (this.state.symbols !== null) {
      if (Array.isArray(this.state.symbols)) {
        return this.state.symbols;
      }
    }
    return null;
  }

  getSelectedLabel() {
    if (this.state.selected === null) {
      return "";
    }
    return this.state.selected.label;
  }

  isLoading() {
    return this.state.isLoading;
  }

  isHome() {
    if (this.props.page !== undefined && this.props.page !== null) {
      if (this.props.page === "home") {
        return true;
      }
    }
    return false;
  }

  renderHome() {
    let isShow = true;
    var isSti = false;
    if (this.logic != null) {
      if (this.logic.isSTI) {
        isSti = true;
      } else {
        isSti = false;
      }
    }
    if (
      this.state.livematrixdata == null ||
      this.state.livematrixdata.livematrix.length === 0 ||
      isSti
    ) {
      isShow = false;
    }
    var code = '';
    if (this.state.selected != null) {
      code = this.state.selected.label.slice(this.state.selected.label.indexOf('(') + 1, this.state.selected.label.indexOf(')'))
    }
    return (
      <div className="holder-lmatrix" >
        <div id="lmatrix">
          <div className="page-header">
            <h2 className="small">Live matrix</h2>
          </div>
          <p className="section-p">
            Shows investors where our bid and offer prices are for a range of
            given underlying prices.
          </p>
        </div>
        <div>
          <Warrant
            symbols={this.getSymbols()}
            disabled={this.isLoading()}
            isHome={this.isHome()}
            onWarrantChange={(selected) => this.onWarrantChange(selected)}
          />
        </div>
        <div
          className="ricbar-home bgcolor-03"
          style={{
            // display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold",
            marginTop: "10px",
            display: !isShow ? "none" : "flex",
            overflowX: 'auto'
          }}
        >
          <div
            className="ticker-home"
            id="ticker"
            style={{ paddingLeft: "10px", lineHeight: '35px' }}
          >
            {this.getSelectedLabel()}
          </div>
          <a href={"/tools/livematrix/" + code} style={{ display: "flex", alignItems: 'center' }} target="_blank">
            <p style={{ margin: "4px", lineHeight: "25px" }}>View more</p>
            <img src="/img/bullet/qlink-achor.png" alt="" style={{ height: 36, width: 36 }} />
          </a>
        </div>
        <div
          style={{
            display: isShow || !isSti ? "none" : "block",
            marginTop: "10px",
            fontStyle: "italic",
          }}
        >
          Please select a warrant to see its live matrix.
        </div>
        <LiveMatrixTodayTable date={this.state.livematrixdata?.last_update} logic={this.logic} page={this.props.page} isLoading={this.state.isLoading} />
      </div>
    );
  }
  childMethod() {
    if (this.refs.child) {
      this.refs.child.onClickTradeWarrantsHere()
    }
  }
  getUpDownClass(value){
    if (value < 0) {
      return "down";
    } else if (value > 0) {
      return "up";
    }
  }

  runLiveHSI() {
    let holders = document.querySelectorAll(".holder-indexfuture");
    try {
      const url = MQSGUtil.getAPIBasePath() + "/LiveIndexJSON?ric=HSI";
      fetch(url)
          .then((res) => res.json())
          .then((data) => {
            holders.forEach(async (holder) => {
              const table = holder.querySelector("table");
              const ticktime = holder.querySelector(".ticktime");
              if (data) {
                const ric = data.code.toString().toUpperCase();
                const therow = table.querySelector(".row_HSI");
                therow.style.display = '';
                const output = new Intl.NumberFormat();
                output.format(data.last);
                therow.querySelector(".col_month").textContent = data.month;
                therow.querySelector(".col_last").textContent = output.format(data.last);
                let chng = data.chng;
                if (chng > 0) {
                  chng = "+" + chng;
                }

                let pchng = data.pchng;
                if (pchng.indexOf("%") >= 0) {
                  pchng = pchng.substr(0, pchng.length - 1);
                }
                if (pchng > 0) {
                  pchng = "+" + pchng;
                }
                let colChng = therow.querySelector(".col_chng");
                colChng.classList.remove("up", "down");
                colChng.classList.add(this.getUpDownClass(data.chng));
                colChng.textContent = chng;
                let colPChng = therow.querySelector(".col_pchng");
                colPChng.classList.remove("up", "down");
                colPChng.classList.add(this.getUpDownClass(data.chng));
                colPChng.textContent = pchng;

                const date = new Date(data.stime);
                let hours = date.getHours() + 1;
                let min = date.getMinutes();
                const today = new Date();
                let s = today.getSeconds();
                if (s < 10) {
                  s = "0" + s;
                }
                hours = hours - 2;
                let ampm = "AM";
                if (hours > 11) {
                  // if (hours !== 12) {
                  //   hours = hours - 12;
                  // }
                  ampm = "PM";
                }
                if (hours < 10) {
                  hours = "0" + hours;
                }
                if (min < 10) {
                  min = "0" + min;
                }
                if (ticktime != null) {
                  ticktime.textContent = hours + ":" + min + ":" + s + " " + ampm;
                }
              }
            })
          })
    } catch (error) {
      console.error(error)
    }
  };

  runLiveHSTECH() {
    try {
      let holders = document.querySelectorAll(".holder-indexfuture");
      const url = MQSGUtil.getAPIBasePath() + "/LiveIndexJSON?ric=HTI";
      fetch(url)
          .then((res) => res.json())
          .then((data) => {
            holders.forEach(async (holder) => {
              let table = holder.querySelector("table");
              let ticktime = holder.querySelector(".ticktime");

              const ric = data.code.toString().toUpperCase();
              const therow = table.querySelector(".row_HSTECH");

              const output = new Intl.NumberFormat();
              output.format(data.last);
              therow.querySelector(".col_month").textContent = data.month;
              therow.querySelector(".col_last").textContent = output.format(data.last);

              let chng = data.chng;
              if (chng > 0) {
                chng = "+" + chng;
              }

              let pchng = data.pchng;
              if (pchng.indexOf("%") >= 0) {
                pchng = pchng.substr(0, pchng.length - 1);
              }
              if (pchng > 0) {
                pchng = "+" + pchng;
              }

              const colChng = therow.querySelector(".col_chng");
              colChng.classList.remove("up", "down");
              colChng.classList.add(this.getUpDownClass(data.chng));
              colChng.textContent = chng;

              const colPChng = therow.querySelector(".col_pchng");
              colPChng.classList.remove("up", "down");
              colPChng.classList.add(this.getUpDownClass(data.chng));
              colPChng.textContent = pchng;

              const update_time = data.stime.split(" ");
              const date = new Date(data.stime);
              let hours = date.getHours() + 1;
              let min = date.getMinutes();
              const today = new Date();
              let s = today.getSeconds();
              if (s < 10) {
                s = "0" + s;
              }
              hours = hours - 1;
              let ampm = "AM";
              if (hours > 11) {
                if (hours !== 12) {
                  hours = hours - 12;
                }
                ampm = "PM";
              }
              if (hours < 10) {
                hours = "0" + hours;
              }
              if (min < 10) {
                min = "0" + min;
              }
              ticktime.textContent = hours + ":" + min + ":" + s + " " + ampm;
            })
          })
    } catch (error) {
      console.error(error)
    }
  }

  runLiveNKY() {
    try {
      let holders = document.querySelectorAll(".holder-indexfuture");
      const url = MQSGUtil.getAPIBasePath() + "/LiveIndexJSON?ric=N225";
      fetch(url)
          .then((res) => res.json())
          .then((data) => {
            const update_time = MQSGUtil.getData(data, "update_time");
            let source = data["SSIcv1"][0]
            holders.forEach(async (holder) => {
              let table = holder.querySelector("table");

              const theRow = table.querySelector(".row_NKY");

              const expiryMonth = MQSGUtil.getData(source, "expiryMonth");
              theRow.querySelector(".col_month").innerHTML = expiryMonth;

              const bid = MQSGUtil.getData(source, "bid");
              theRow.querySelector(".col_last").innerHTML = bid;

              const net = MQSGUtil.getData(source, "net");
              const netupdown = MQSGUtil.getUpDownClass(net);
              const netString = MQSGUtil.signedString(net, "", "");
              const pct = MQSGUtil.getData(source, "pct");
              const pctString = MQSGUtil.signedString(pct, "", "");
              const pctupdown = MQSGUtil.getUpDownClass(pct);

              theRow.querySelector(".col_chng").classList.remove("up", "down");
              theRow.querySelector(".col_chng").classList.add(this.getUpDownClass(netupdown));
              theRow.querySelector(".col_chng").innerHTML = netString;
              theRow.querySelector(".col_pchng").classList.remove("up", "down");
              theRow.querySelector(".col_pchng").classList.add(this.getUpDownClass(pctupdown));
              theRow.querySelector(".col_pchng").innerHTML = pctString;

              let ticktime = holder.querySelector(".ticktime");
              ticktime.innerHTML = update_time;
            })
          })
    } catch (error) {
      console.error(error)
    }
  };

  runLiveSIMSCI() {
    try {
      let holders = document.querySelectorAll(".holder-indexfuture");
      const url = MQSGUtil.getAPIBasePath() + "/LiveIndexJSON?ric=SIMSCI";
      fetch(url)
          .then((res) => res.json())
          .then((data) => {
            holders.forEach(async (holder) => {
              let table = holder.querySelector("table");
              let ticktime = holder.querySelector(".ticktime");

              const ric = data.code.toString().toUpperCase();
              const therow = table.querySelector(".row_SIMSCI");

              const output = new Intl.NumberFormat();
              output.format(data.last);
              therow.querySelector(".col_month").textContent = data.month;
              therow.querySelector(".col_last").textContent = output.format(data.last);

              let chng = data.chng;
              if (chng > 0) {
                chng = "+" + chng;
              }

              let pchng = data.pchng;
              if (pchng.indexOf("%") >= 0) {
                pchng = pchng.substr(0, pchng.length - 1);
              }
              if (pchng > 0) {
                pchng = "+" + pchng;
              }

              const colChng = therow.querySelector(".col_chng");
              colChng.classList.remove("up", "down");
              colChng.classList.add(this.getUpDownClass(data.chng));
              colChng.textContent = chng;

              const colPChng = therow.querySelector(".col_pchng");
              colPChng.classList.remove("up", "down");
              colPChng.classList.add(this.getUpDownClass(data.chng));
              colPChng.textContent = pchng;

              const update_time = data.stime.split(" ");
              const date = new Date(data.stime);
              let hours = date.getHours() + 1;
              let min = date.getMinutes();
              const today = new Date();
              let s = today.getSeconds();
              if (s < 10) {
                s = "0" + s;
              }
              hours = hours - 1;
              let ampm = "AM";
              if (hours > 11) {
                if (hours !== 12) {
                  hours = hours - 12;
                }
                ampm = "PM";
              }
              if (hours < 10) {
                hours = "0" + hours;
              }
              if (min < 10) {
                min = "0" + min;
              }
              ticktime.textContent = hours + ":" + min + ":" + s + " " + ampm;
            })
          })
    } catch (error) {
      console.error(error)
    }
  }

  runLiveSP500() {
    try {
      let holders = document.querySelectorAll(".holder-indexfuture");
      const url = MQSGUtil.getAPIBasePath() + "/LiveIndexJSON?ric=S%26P500";
      fetch(url)
          .then((res) => res.json())
          .then((data) => {
            const update_time = MQSGUtil.getData(data, "update_time");
            if (data["SSIcv1"]?.length > 0) {
              let source = data["SSIcv1"][0]
              holders.forEach(async (holder) => {
                let table = holder.querySelector("table");

                const theRow = table.querySelector(".row_SP500");

                const expiryMonth = MQSGUtil.getData(source, "expiryMonth");
                theRow.querySelector(".col_month").innerHTML = expiryMonth;

                const bid = MQSGUtil.getData(source, "bid");
                theRow.querySelector(".col_last").innerHTML = bid;

                const net = MQSGUtil.getData(source, "net");
                const netupdown = MQSGUtil.getUpDownClass(net);
                const netString = MQSGUtil.signedString(net, "", "");
                const pct = MQSGUtil.getData(source, "pct");
                const pctString = MQSGUtil.signedString(pct, "", "");
                const pctupdown = MQSGUtil.getUpDownClass(pct);

                theRow.querySelector(".col_chng").classList.remove("up", "down");
                theRow.querySelector(".col_chng").classList.add(this.getUpDownClass(netupdown));
                theRow.querySelector(".col_chng").innerHTML = netString;
                theRow.querySelector(".col_pchng").classList.remove("up", "down");
                theRow.querySelector(".col_pchng").classList.add(this.getUpDownClass(pctupdown));
                theRow.querySelector(".col_pchng").innerHTML = pctString;

                let ticktime = holder.querySelector(".ticktime");
                ticktime.innerHTML = update_time;
              })
            }
          })
    } catch (error) {
      console.error(error)
    }
  };

  getMorningMarketBuzzTile(underlying) {
    try {
      const url = MQSGUtil.getAPIBasePath() + `/NewsTableServlet?sp=todayhighlight&start=0&underlying=${underlying.replace('&', '%26')}`;
      fetch(url)
          .then((res) => res.json())
          .then((data) => {
            let news = data.newsList
            if (news.length > 0) {
              this.setState({latestNewTitle: news[0].en_title, latestNewUnderlying: underlying})
            }
          })
    } catch (error) {
      console.error(error)
    }
  }

  refreshIndex(arg) {
    if (arg === "HSI") {
      this.setState({showHSILiveIndex: true, showNKYLiveIndex: false, showHSTECHLiveIndex: false, showSIMSCILiveIndex: false, showSP500LiveIndex: false})
      this.runLiveHSI();
    } else if (arg === "NKY" || arg === "N225") {
      this.setState({showHSILiveIndex: false, showNKYLiveIndex: true, showHSTECHLiveIndex: false, showSIMSCILiveIndex: false, showSP500LiveIndex: false})
      this.runLiveNKY();
    } else if (arg === "HSTECH") {
      this.setState({showHSILiveIndex: false, showNKYLiveIndex: false, showHSTECHLiveIndex: true, showSIMSCILiveIndex: false, showSP500LiveIndex: false})
      this.runLiveHSTECH();
    } else if (arg === "SIMSCI") {
      this.setState({showHSILiveIndex: false, showNKYLiveIndex: false, showHSTECHLiveIndex: false, showSIMSCILiveIndex: true, showSP500LiveIndex: false})
      this.runLiveSIMSCI()
    } else if (arg === "S&P500") {
      this.setState({showHSILiveIndex: false, showNKYLiveIndex: false, showHSTECHLiveIndex: false, showSIMSCILiveIndex: false, showSP500LiveIndex: true})
      this.runLiveSP500()
    } else {
      this.setState({showHSILiveIndex: false, showNKYLiveIndex: false, showHSTECHLiveIndex: false, showSIMSCILiveIndex: false, showSP500LiveIndex: false})
    }
  }

  renderPage() {
    let ric = null;
    let name = null;
    let isSpShow = false;
    let isWarrantsShow = false;
    let isUnderyingShow = false;
    let future_dsply_name = '';
    let spanShow = false;
    if (
      typeof this.state.selected !== "undefined" &&
      this.state.selected !== null
    ) {
      ric = this.state.selected.value;
      name = this.state.selected.label;
      if (
        this.state.selected.label.indexOf('S&P') != -1 ||
        this.state.selected.label.indexOf('DJI') != -1 ||
        this.state.selected.label.indexOf('HSI') != -1 ||
        this.state.selected.label.indexOf('HSTECH') != -1 ||
        this.state.selected.label.indexOf('NKY') != -1 ||
        this.state.selected.label.indexOf('SIMSCI') != -1 ||
        this.state.selected.label.indexOf('Sea') != -1 ||
        this.state.selected.label.indexOf('NASDAQ') != -1
      ) {
        isWarrantsShow = true;
      } else {
        if (this.state.selected.future_dsply_name && this.state.selected.future_dsply_name != '' && this.state.selected.future_dsply_name != '-') {
          isUnderyingShow = true;
          future_dsply_name = this.state.selected.future_dsply_name;
        }
      }
    }
    var isShow = false;
    if (this.state.tabNum == 2) {
      isShow = true
    } else {
      isShow = false
    }
    return (
      <div id="livematrix" className="pageobj" onClick={() => this.childMethod()}>
        <BreadcrumbBar
          link="/home"
          group="Warrant tools"
          title="Live matrix"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="section">
                <PageHeader title="Live matrix" ref="child" />
                <div>
                  Shows investors where our bid and offer prices are for a range
                  of given underlying prices.{" "}
                  <span
                    className="ttip"
                    data-target="livematrixdescription"
                    data-hasqtip="0"
                    data-tip
                    data-for="indicator-hotdw-tooltip"
                    data-tooltip-id="indicator-hotdw-tooltip"
                  >
                    <mark>
                      Read on to find out how the matrix for put warrants differ
                      from calls
                    </mark>
                    <ReactTooltip
                      id="indicator-hotdw-tooltip"
                      delayHide={100}
                      place="right"
                      type="warning"
                      effect="solid"
                      backgroundColor="#FECC0A"
                      textColor="#000"
                      className="ttip_con"
                    >
                      <div
                        style={{
                          color: "#333333",
                        }}
                      >
                        Put prices move in the opposite direction to the share
                        price, for this reason the price matrices
                        <br />
                        are the opposite to what you would find for a call
                        warrant, where..
                        <br />
                        The{" "}
                        <span style={{ textDecoration: "underline" }}>
                          offer
                        </span>{" "}
                        price for the warrant is based on the bid price of the
                        underlying
                        <br />
                        The{" "}
                        <span style={{ textDecoration: "underline" }}>
                          bid{" "}
                        </span>
                        price for the warrant is based on the offer price of the
                        underlying
                      </div>
                    </ReactTooltip>
                  </span>
                </div>
                <p style={{ marginTop: "10px" }}>
                  The live matrix consists of a table showing a range of prices for the underlying’s bid/offer and warrant’s bid/offer.
                </p>
                <p style={{ marginTop: "10px" }}>
                  Call prices move in the same direction as the share price. The call warrant offer price is based on the underlying offer price while the bid price of the warrant is based on the underlying bid price.
                </p>
                <p style={{ marginTop: "10px" }}>
                  On the other hand, the offer price for a put warrant is based on the bid price of the underlying while the bid price for the warrant is based on the offer price of the underlying"
                </p>
                <div id="top_area">
                  <Underlying
                    underlying={this.state.underlying}
                    onUnderlyingChange={(option) =>
                      this.onUnderlyingChange(option)
                    }
                  />
                  <p style={{ margin: '15px 0px' }}></p>
                  <Warrant
                    ref='selectWarrant'
                    symbols={this.getSymbols()}
                    disabled={this.isLoading()}
                    onWarrantChange={(selected) =>
                      this.onWarrantChange(selected)
                    }
                  />

                  <div className="tablist">
                    <div id="topTabs" className="tablist-left" style={{ overflowX: 'auto' }}>
                      <table>
                        <tbody>
                          <tr>
                            <td
                              className={classNames({
                                active: this.state.tabNum == 1,
                              })}
                              onClick={() => this.setTab(1)}
                            >
                              <div className="tablist-item">
                                <div className="tablist-item-txt">
                                  Historical Performance
                                </div>
                              </div>
                            </td>
                            <td
                              className={classNames({
                                active: this.state.tabNum == 4,
                              })}
                              onClick={() => this.setTab(4)}
                            >
                              <div className="tablist-item">
                                <div className="tablist-item-txt">
                                  Warrant terms
                                </div>
                              </div>
                            </td>
                            <td
                              className={classNames({
                                active: this.state.tabNum == 2,
                              })}
                              onClick={() => this.setTab(2)}
                            >
                              <div className="tablist-item">
                                <div className="tablist-item-txt">
                                  Warrant Calculator
                                </div>
                              </div>
                            </td>
                            <td
                              className={classNames({
                                active: this.state.tabNum == 3,
                              })}
                              onClick={() => this.setTab(3)}
                            >
                              <div className="tablist-item">
                                <div className="tablist-item-txt">
                                  Live matrix
                                </div>
                              </div>
                            </td>
                            <td
                              className={classNames({
                                active: this.state.tabNum == 5,
                              })}
                              onClick={() => this.setTab(5)}
                            >
                              <div className="tablist-item">
                                <div className="tablist-item-txt">
                                  Exposure simulator
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {
                    this.state.tabNum == 3 ? <LiveMatrixTodayTable date={this.state.livematrixdata?.last_update} logic={this.logic} isLoading={this.state.isLoading} selected={this.state.selected} /> : this.state.tabNum == 4 ? <IndtableShow
                      warrantdata={this.state.livematrixdata && this.state.livematrixdata.ric_data}
                    /> : null
                  }

                  <div style={{ display: this.state.tabNum == 1 ? 'block' : 'none' }}>
                    <TermsChart
                      selected={this.state.selected}
                      period={this.state.period}
                      chartdata={this.state.chartdata}
                      onPeriodChange={this.onPeriodChange.bind(this)}
                      warrantdata={this.state.livematrixdata && this.state.livematrixdata.ric_data}
                    />
                  </div>
                  {
                    this.state.tabNum === 2 && this.state.livematrixdata != null && <WrCalculatorBox isRightPanel={true} ric={ric} marketdata={this.state.livematrixdata && this.state.livematrixdata.ric_data} />
                  }
                  {
                    this.state.tabNum == 5 && this.state.livematrixdata != null && <WarrantHedget
                      warrantdata={this.state.livematrixdata.ric_data}
                    />
                  }
                </div>

              </div>
            </div>
            <div className="hidden-xs hidden-sm col-md-4">
              <LiveIndexFuture liveMatrix={true} isSti={this.state.selected} />
              <AdvertBox />
              <div id="qlink-dwvideo" className="holder-qlink-dwvideo ">
                <div className="videoplayer">
                  <div className="videoplayer-container">
                    <iframe
                      title="education video"
                      frameBorder="0"
                      allowFullScreen=""
                      className="dwvideo-iframe"
                      src="https://www.youtube.com/embed/_EqFjoCgE08?autoplay=0&index=0&rel=0&wmode=opaque"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-md-14 hidden-xs hidden-sm">
                <LastView lastviewrics={this.state.lastviewrics} />
                <div className="his-index">
                  <div className="holder-indexfuture" style={{ display: this.state.showHSILiveIndex || this.state.showNKYLiveIndex || this.state.showHSTECHLiveIndex || this.state.showSIMSCILiveIndex ? "" : "none" }}>
                    <div
                        id="indexfuture"
                        className="section qlink-section"
                    >
                      <div className="page-header">
                        <h1 className="small">Live index futures</h1>
                      </div>
                      <table className="table table-striped table-noHeaderborder">
                        <thead>
                        <tr>
                          <th>Index</th>
                          <th>Contract</th>
                          <th>Last</th>
                          <th>Change</th>
                          <th>Change%</th>
                          <th />
                        </tr>
                        </thead>
                        <tbody>
                          <tr className="row_HSI" style={{ display: this.state.showHSILiveIndex ? "" : "none"}}>
                            <td style={{ padding: "2px" }}>
                              <Link to="/tools/dwranking/HSI">HSI*</Link>
                            </td>
                            <td className="col_month" style={{ padding: "2px" }}>
                              -
                            </td>
                            <td className="col_last" style={{ padding: "2px" }}>
                              -
                            </td>
                            <td
                                className="col_chng val up"
                                style={{ padding: "2px" }}
                            >
                              -
                            </td>
                            <td
                                className="col_pchng val up"
                                style={{ padding: "2px" }}
                            >
                              -
                            </td>
                            <td style={{ padding: "2px" }}>
                              <img
                                  className="refresh_index"
                                  style={{
                                    cursor: "pointer",
                                    width: "30px",
                                    margin: "4px 0",
                                  }}
                                  onClick={() => this.refreshIndex("HSI")}
                                  src="/img/bullet/refresh.png"
                              />
                            </td>
                          </tr>
                          <tr className="row_NKY" style={{ display: this.state.showNKYLiveIndex ? "" : "none" }}>
                            <td style={{ padding: "2px" }}>
                              <Link to="/tools/dwranking/HSCEI">Nikkei</Link>
                            </td>
                            <td className="col_month" style={{ padding: "2px" }}>
                              -
                            </td>
                            <td className="col_last" style={{ padding: "2px" }}>
                              -
                            </td>
                            <td
                                className="col_chng val"
                                style={{ padding: "2px" }}
                            >
                              -
                            </td>
                            <td
                                className="col_pchng val"
                                style={{ padding: "2px" }}
                            >
                              -
                            </td>
                            <td style={{ padding: "2px" }}>
                              <img
                                  onClick={() => this.refreshIndex("NKY")}
                                  className="refresh_index"
                                  style={{ cursor: "pointer", width: "22px" }}
                                  src="/img/bullet/refresh.png"
                              />
                            </td>
                          </tr>
                          <tr className="row_HSTECH" style={{ display: this.state.showHSTECHLiveIndex ? "" : "none" }}>
                            <td style={{ padding: "2px" }}>
                              <Link to="/tools/dwranking/HSTECH">HSTECH*</Link>
                            </td>
                            <td className="col_month" style={{ padding: "2px" }}>
                              -
                            </td>
                            <td className="col_last" style={{ padding: "2px" }}>
                              -
                            </td>
                            <td
                                className="col_chng val"
                                style={{ padding: "2px" }}
                            >
                              -
                            </td>
                            <td
                                className="col_pchng val"
                                style={{ padding: "2px" }}
                            >
                              -
                            </td>
                            <td style={{ padding: "2px" }}>
                              <img
                                  className="refresh_index"
                                  style={{ cursor: "pointer", width: "22px" }}
                                  src="/img/bullet/refresh.png"
                                  onClick={() => this.refreshIndex("HSTECH")}
                              />
                            </td>
                          </tr>
                          <tr className="row_SIMSCI" style={{ display: this.state.showSIMSCILiveIndex ? "" : "none" }}>
                            <td style={{ padding: "2px" }}>
                              <Link to="/tools/dwranking/SIMSCI">SIMSCI*</Link>
                            </td>
                            <td className="col_month" style={{ padding: "2px" }}>
                              -
                            </td>
                            <td className="col_last" style={{ padding: "2px" }}>
                              -
                            </td>
                            <td
                                className="col_chng val"
                                style={{ padding: "2px" }}
                            >
                              -
                            </td>
                            <td
                                className="col_pchng val"
                                style={{ padding: "2px" }}
                            >
                              -
                            </td>
                            <td style={{ padding: "2px" }}>
                              <img
                                  className="refresh_index"
                                  style={{ cursor: "pointer", width: "22px" }}
                                  src="/img/bullet/refresh.png"
                                  onClick={() => this.refreshIndex("SIMSCI")}
                              />
                            </td>
                          </tr>
                          <tr className="row_SP500" style={{ display: this.state.showSP500LiveIndex ? "" : "none" }}>
                            <td style={{ padding: "2px" }}>
                              <Link to="/tools/dwranking/SP500">S&P500</Link>
                            </td>
                            <td className="col_month" style={{ padding: "2px" }}>
                              -
                            </td>
                            <td className="col_last" style={{ padding: "2px" }}>
                              -
                            </td>
                            <td
                                className="col_chng val"
                                style={{ padding: "2px" }}
                            >
                              -
                            </td>
                            <td
                                className="col_pchng val"
                                style={{ padding: "2px" }}
                            >
                              -
                            </td>
                            <td style={{ padding: "2px" }}>
                              <img
                                  onClick={() => this.refreshIndex("S&P500")}
                                  className="refresh_index"
                                  style={{ cursor: "pointer", width: "22px" }}
                                  src="/img/bullet/refresh.png"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p className="priceUpdateInfo">
                        <em>
                        <span>* Click refresh button for live quote</span>
                          <br />Price as at {this.state.date1}<span className="ticktime" />
                          <br />
                          <p className="indicator-item">
                            <span className="icon-time indicator-item-icon" />
                            <span
                                className="ttip"
                                data-tip
                                data-for={"dbPowerTooltip"}
                            >
                            HSI data provided by DB Power Online Limited
                            [Disclaimer]
                          </span>
                          </p>
                          <ReactTooltip
                              id={"dbPowerTooltip"}
                              delayHide={100}
                              place="right"
                              type="warning"
                              effect="solid"
                              clickable={true}
                          >
                            <div className="bg-tooltip">
                              <p
                                  className="tooltip-content"
                                  dangerouslySetInnerHTML={{
                                    __html: 'DB Power Online Limited, “HKEX Information Services Limited, its holding companies and/or any subsidiaries of such holding companies”, China Investment Information Services Limited, third party information providers endeavor to ensure the accuracy and reliability of the information provided but do not guarantee its accuracy or reliability and accept no liability (whether in tort or contract or otherwise) for any loss or damage arising from any inaccuracies or omissions.<br><br>The BMP Service is aimed for investor reference. The BMP only provides basic market information for reference purposes and the investors should consider if they would need more detailed market information for reference or to facilitate their investment decision.<br><img style="width: 160px" src="/img/home/dbpower.png">'
                                  }}
                              />
                            </div>
                          </ReactTooltip>
                        </em>
                      </p>
                    </div>
                  </div>
                </div>
                <div id="dailyhighlights" className="section qlink-section" style={{display: this.state.displayMorningMarketBuzz  ? "" : "none"}}>
                  <div className="page-header">
                    <h1 className="small">Morning Market Buzz</h1>
                  </div>
                  <div className="news-title" >
                    {this.state.latestNewTitle}
                  </div>
                  <div style={{ textAlign: "right", marginRight: "20px" }}>
                    <Link to={"/marketnews/dailyhighlights/" + this.state.latestNewUnderlying} target="_blank">
                      <img src="/img/bullet/qlink-achor.png" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.isHome()) {
      return this.renderHome();
    } else {
      return this.renderPage();
    }
  }
}

class Warrant extends Component {
  onChange(selected) {
    if (this.props.isHome) {
      this.props.onWarrantChange(selected);
    } else {
      // window.location.href = '/tools/livematrix/' + selected.label.slice(selected.label.indexOf('(') + 1, selected.label.indexOf(')'))
      window.history.replaceState(
        {},
        "",
        `/tools/livematrix/${selected.label.slice(selected.label.indexOf('(') + 1, selected.label.indexOf(')'))}`
      );
      this.props.onWarrantChange(selected);
    }
  }
  getDisplayName() {
    const symbols = [];
    if (this.props.symbols !== null && this.props.symbols.length > 0) {
      this.props.symbols.forEach((element) => {
        if (
          "ric" in element &&
          "dsply_nmll" in element &&
          "security_code" in element
        ) {
          const item = {
            label: element.dsply_nmll + " (" + element.security_code + ")",
            value: element.ric,
            future_dsply_name: element.future_dsply_name
          };
          symbols.push(item);
        }
      });
    }
    return symbols;
  }
  onMenuOpen() {
    this.refs.warrant.onMenuOpen()
  }
  filterOption(candidate, input) {
    return createFilter()(candidate, input);
  };
  render() {
    return (
      <div id="dropdown-warrant">
        <div>
          <Select
            className="center"
            placeholder="Select warrant"
            options={this.getDisplayName()}
            onChange={this.onChange.bind(this)}
            isDisabled={this.props.disabled}
            ref='warrant'
            styles={{
              option: (provided, state) => ({
                ...provided,
                textAlign: "center",
                ':hover': {
                  backgroundColor: '#1A73E8',
                }
              }),
              valueContainer: (provided) => ({
                ...provided,
                justifyContent: 'center'
              })
            }}
            filterOption={this.filterOption}
          // filterOption={createFilter({
          //   ignoreCase: true,
          //   ignoreAccents: true,
          //   trim: true,
          //   // matchFrom: 'any',
          //   matchFrom: 'start',
          // })}
          />
        </div>
      </div>
    );
  }
}
class Underlying extends React.Component {
  constructor(props) {
    super(props);
    this.underlyingOption = null;
  }

  onChange(selected) {
    this.props.onUnderlyingChange(selected);
  }
  getUnderlyingOption() {
    if (this.underlyingOption === null) {
      if (this.props.underlying !== null) {
        if ("list" in this.props.underlying) {
          const symbolData = this.props.underlying.list;

          if (Array.isArray(symbolData)) {
            this.underlyingOption = [];
            symbolData.forEach((data) => {
              if ("underlying_ric" in data) {
                const displayname = MQSGUtil.getUnderlyingTickerDisplayName(
                  data.underlying_ric,
                  data.underlying_ticker,
                  false
                );
                if (displayname !== null) {
                  data["label"] = displayname;
                  data["value"] = data.underlying_ticker;
                  this.underlyingOption.push(data);
                }
              }
            });
          }
        }
      }
    }
    return this.underlyingOption;
  }
  filterOption(candidate, input) {
    var value = this.stripscript(input).toUpperCase();
    var labelValue = this.stripscript(candidate.label).toUpperCase()
    if (labelValue.indexOf(value) != -1) return true;
    return createFilter()(candidate, input);
  }
  stripscript(s) {
    var pattern = new RegExp(/[`~!@#$^\-&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g);
    var rs = "";
    for (var i = 0; i < s.length; i++) {
      rs = rs + s.substr(i, 1).replace(pattern, '');
    }
    return rs;
  }

  render() {
    const customStyles = {
      menu: (provided) => ({
        ...provided,
        zIndex: 10,
        textAlign: "center",
      }),
      option: (provided, state) => ({
        ...provided,
        color: '#000',
        backgroundColor: '#FFFFFF',
        ':hover': {
          backgroundColor: '#1A73E8',
        },
      }),
      valueContainer: (provided) => ({
        ...provided,
        justifyContent: 'center'
      })
    };

    return (
      <div id="dropdown-underlying">
        <div>
          <Select
            placeholder="Select underlying"
            options={this.getUnderlyingOption()}
            styles={customStyles}
            onChange={this.onChange.bind(this)}
            filterOption={this.filterOption.bind(this)}
          />
        </div>
      </div>
    );
  }
}
class TableGeneral extends Component {
  getData(fieldName, dp = null) {
    if (
      this.props.warrantdata !== null &&
      fieldName in this.props.warrantdata
    ) {
      const value = this.props.warrantdata[fieldName];
      if (dp !== null) {
        return Number(value).toFixed(dp);
      }
      return value;
    }
    return "";
  }

  getTheta() {
    // "last_trading_date": "23 Feb 21"
    const lasttradingdate = this.getData("last_trading_date");
    const currDate = new Date();
    const currDateMS = currDate.getTime();
    if (lasttradingdate.length > 0) {
      const lasttradingdateMS = Date.parse(lasttradingdate);
      const diff = currDateMS - lasttradingdateMS;
      // check if expired
      if (diff <= 24 * 60 * 60 * 1000) {
        return this.getData("theta", 4);
      }
    }
    return "-";
  }

  getMoneynessClass(nc) {
    let cnm = "";
    const value = Number(nc);
    if (!isNaN(value)) {
      if (value === 1) {
        cnm = "val mny up";
      } else if (value === 2) {
        cnm = "val mny down";
      }
    }
    return cnm;
  }

  getMoneynessName(nc) {
    let nm = "";
    const value = Number(nc);
    if (!isNaN(value)) {
      if (value === 1) {
        return "ITM";
      } else if (value === 2) {
        return "OTM";
      } else {
        return "ATM";
      }
    }
    return nm;
  }

  render() {
    const hstclose = this.getData("HST_CLOSE");
    const trdprc1 = this.getData("TRDPRC_1");
    const openprc = this.getData("OPEN_PRC");
    const acvol1scaled = this.getData("ACVOL_1_SCALED");
    const high1 = this.getData("HIGH_1");
    const turnoverscaled = this.getData("TURNOVER_SCALED");
    const low1 = this.getData("LOW_1");
    const underlyingprice = this.getData("underlying_price");
    const ticker = this.getData("ticker");
    const effectivegearing = this.getData("effective_gearing");
    const underlyingticker = this.getData("underlying_ticker");
    const delta = this.getData("delta");
    const type = this.getData("type");
    const deltaperwrnt = this.getData("delta_per_wrnt");
    const issuername = this.getData("issuer_name");
    const sensitivity = this.getData("sensitivity");
    const exerciseprice = this.getData("exercise_price");
    const breakevenprice = this.getData("breakeven_price");
    const tradedate = this.getData("TRADE_DATE");
    const premium = this.getData("premium");
    const maturity = this.getData("maturity");
    const impliedvolatility = this.getData("implied_volatility");
    const lasttradingdate = this.getData("last_trading_date");
    const theta = this.getTheta();
    const daystomaturity = this.getData("days_to_maturity") + " days";
    const intrinsicvalue = this.getData("intrinsic_value");
    const convratio = this.getData("conv_ratio");
    const moneyness = this.getData("moneyness");
    const moneynessClass = this.getMoneynessClass(moneyness);
    const moneynessdisplaystring =
      this.getData("percent_moneyness") +
      "% " +
      this.getMoneynessName(moneyness);

    return (
      <div>
        <div id="srtable" style={{ position: "relative" }}>
          <table className="table priorTable">
            <tbody>
              <tr>
                <th
                  className="srtable-leftlbl bgcolor-01"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Prior
                </th>
                <td
                  className="srtable-leftval bgcolor-01 val HST_CLOSE"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {hstclose}
                </td>
                <th
                  className="srtable-rightlbl bgcolor-02"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Last trade
                </th>
                <td
                  className="srtable-rightval bgcolor-02 val TRDPRC_1"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {trdprc1}
                </td>
              </tr>
              <tr>
                <th className="srtable-leftlbl" style={{ border: 0, textAlign: 'left' }}>
                  Open
                </th>
                <td
                  className="srtable-leftval val OPEN_PRC"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {openprc}
                </td>
                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                  Traded volume ('000)
                </th>
                <td className="srtable-rightval val ACVOL_1_SCALED" style={{ textAlign: 'left' }}>
                  {acvol1scaled}
                </td>
              </tr>
              <tr>
                <th
                  className="srtable-leftlbl bgcolor-01"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  High
                </th>
                <td
                  className="srtable-leftval bgcolor-01 val HIGH_1"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {high1}
                </td>
                <th
                  className="srtable-rightlbl bgcolor-02"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  Traded value ('000 SGD)
                </th>
                <td
                  className="srtable-rightval bgcolor-02 val TURNOVER_SCALED"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {turnoverscaled}
                </td>
              </tr>
              <tr>
                <th className="srtable-leftlbl" style={{ textAlign: 'left' }}>Low</th>
                <td className="srtable-leftval val LOW_1" style={{ border: 0, textAlign: 'left' }}>
                  {low1}
                </td>
                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                  Underlying price
                </th>
                <td
                  className="srtable-rightval val underlying_price"
                  style={{ border: 0, textAlign: 'left' }}
                >
                  {underlyingprice}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
