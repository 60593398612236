import React, { Component } from "react";
import { Link } from 'react-router-dom'
import MQSGUtil from "../../../components/mqsgUtil";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Timeline from "highcharts/modules/timeline.js"
import Nodata from "highcharts/modules/no-data-to-display";
import moment from "moment"
Nodata(Highcharts);
Timeline(Highcharts);
export default class DataModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalData: [
                {
                    id: 1,
                    headText: "The NASDAQ-100 Index",
                    profileImg: "/img/education/sflag_us.svg",
                    text: "The NASDAQ-100® Index (“NDX”) is one of the world’s distinguished large-cap growth indexes and is home to some of the world’s most innovative companies including Apple, Google, Intel and Tesla. The index includes 100 of the largest U.S. and international non-financial companies listed on the NASDAQ Stock Market based on market capitalization. The index reflects companies across major industry groups including computer hardware and software, telecommunications, retail/wholesale trade and biotechnology, and does not contain securities of financial or investment companies.",
                    img1: "/img/education/NASDAQ1.svg",
                    img2: "/img/education/NASDAQ2.svg",
                    img3: "/img/education/NASDAQ3.svg",
                    text1: "100 of the largest",
                    text2: "U.S. and international non-financial companies based on market capitalization",
                    text3: "Houses innovative companies",
                    text4: "such as Apple, Google and Tesla",
                    text5: "Heavy allocation",
                    text6: "to top performing industries like Technology and Consumer Services",
                    tableHeading: "NDX"
                },
                {
                    id: 2,
                    headText: "The Dow Jones Industrial Average Index",
                    profileImg: "/img/education/sflag_us.svg",
                    text: "The Dow Jones Industrial Average® Index (“DJIA”) is a price-weighted measure of 30 U.S. blue-chip companies covering all industries except for transportation and utilities. Aside from being the most recognizable stock indicator in the world, it is also the most widely quoted indicator of U.S. stock market activity and the oldest continuous barometer of the U.S. stock market with a history of more than 100 years.",
                    img1: "/img/education/DJ1v2.png",
                    img2: "/img/education/DJ2.svg",
                    img3: "/img/education/DJ3.svg",
                    text1: "30 U.S. blue-chip companies",
                    text2: "covering all industries except for transportation and utilities",
                    text3: "Most widely quoted",
                    text4: "indicator of U.S. stock market activity",
                    text5: "Oldest",
                    text6: "continuing U.S. market index",
                    tableHeading: "DJIA"
                },
                {
                    id: 3,
                    headText: "Hang Seng Index",
                    profileImg: "/img/education/sflag_hk.svg",
                    text: "The Hang Seng Index (“HSI”) is the most widely quoted performance indicator of the Hong Kong share market, and one of the most watched indexes in the region. Comprising 50 of the largest shares on the Hong Kong exchange, it tracks the movement in the Hong Kong market and is very closely linked to the Chinese economy. Many investors across the world use the HSI as a proxy to invest into China.",
                    img1: "/img/education/HSI1.svg",
                    img2: "/img/education/HSI2.svg",
                    img3: "/img/education/HSI3.svg",
                    text1: "Comprises 50",
                    text2: "of the largest and most liquid stocks listed on the HKEX",
                    text3: "Captures major sectors",
                    text4: "with constituents grouped into Finance, Utilities, Properties, and Commerce and Industry",
                    text5: "Tracks the HK market",
                    text6: "and is closely linked to the Chinese economy",
                    tableHeading: "HSI"
                },
                {
                    id: 4,
                    headText: "Hang Seng TECH Index",
                    profileImg: "/img/education/sflag_hk.svg",
                    text: `The Hang Seng TECH Index (“HSTECH”) comprises the largest companies listed on the Hong Kong Stock Exchange which have high exposure in selected technology themes. First launched in July 2020, the more notable constituents include well-known names such as Alibaba, Tencent, Meituan and Xiaomi (commonly referred to as ‘ATMX’) which have a combined weight of more than 30%. Investors will be able to gain exposure to these heavyweights as well as a diverse spectrum of themes within the tech sector including internet, fintech, cloud, e-commerce and digital while minimizing any single stock specific risk.<br/><br/>View more information on the HSTECH <a target=${"_blank"} href=${"/my/warrant-education/gain-exposure"}>here</a>.<br/></br><i>Note: The chart below includes back-tested data compiled by the Hang Seng Indexes Company Limited for the period before the HSTECH’s launch date of 27 July 2020.</i>`,
                    img1: "/img/education/HSTECH1.svg",
                    img2: "/img/education/HSTECH2.svg",
                    img3: "	/img/education/HSTECH3.svg",
                    text1: "Higher volatility",
                    text2: "than the HSI on average",
                    text3: "Ease of access",
                    text4: "Trade warrants on Bursa using your existing trading account",
                    text5: "Broad coverage",
                    text6: "of the technology sector, including the popular ATMX!",
                    tableHeading: "HSTECH"
                },
                // {
                //     id: 5,
                //     headText: "MSCI Singapore Free Index",
                //     profileImg: "/img/education/singapore_flag_svg.png",
                //     text: "Made up of 23 stocks (as of 31 Oct 2023) covering approximately 85% of the free float-adjusted market capitalisation of the Singapore equity universe, the MSCI Singapore Free Index (“SiMSCI Index”) is designed to measure the performance of the large and mid-cap segments of the Singapore market.</br></br>The SIMSCI includes shares of Singapore companies listed overseas such as SEA ADR and Grab amongst its constituents, while STI focuses on Singapore domestic names that are listed on the SGX only. Nevertheless, the SIMSCI has a 98% correlation to the STI Index (according to SGX data at <a href='https://www.sgx.com/derivatives/products/sgxsimsci?cc=SGP' target='_blank'>https://www.sgx.com/derivatives/products/sgxsimsci?cc=SGP</a> in Aug 2022)</br></br>The active SIMSCI futures traded on the SGX, allow investors to trade the moves of the SIMSCI index.</br>",
                //     img1: "/img/education/img1.png",
                //     img2: "/img/education/img2.png",
                //     img3: "/img/education/img3.png",
                //     text1: "Comprises large and mid-cap stocks",
                //     text2: "in Singapore. Bears more than 98% correlation to the Straits Times Index.",
                //     text3: "Includes foreign-listed fintech names",
                //     text4: "such as SEA ADR and Grab listed in the US.",
                //     text5: " Active futures market",
                //     text6: "compared to the less active STI futures market. ",
                //     tableHeading: "SIMSCI"
                // },
                {
                    id: 5,
                    headText: "Nikkei 225 Index",
                    profileImg: "/img/education/japan_flag_svg.png",
                    text: "The Nikkei Stock Average, commonly referred to as the Nikkei 225 Index, is the most widely quoted average of Japanese equities . It is a price-weighted equity index, which consists of 225 stocks in the 1st section of the Tokyo Stock Exchange, and the components are reviewed once a year.</br></br>In 1986, SGX pioneered the world's first futures contract based on the Japanese stock market -- the SGX Nikkei 225 Index Futures. The SGX Nikkei 225 Index is actively traded by participants all over the globe, and remains the dominant offshore Nikkei market during Asia trading hours</br>",
                    img1: "/img/education/img4.png",
                    img2: "/img/education/img5.png",
                    img3: "/img/education/img6.png",
                    text1: "Indicator of Japanese stock market movements",
                    text2: "",
                    text3: "Actively traded futures index listed on the SGX",
                    text4: "is the dominant offshore Nikkei market during Asia trading hours",
                    text5: "Trades extended hours until 515AM and during Japanese holidays",
                    text6: "with the exception of New Year’s Day (1 January)",
                    tableHeading: "Nikkei 225"
                },
                {
                    id: 6,
                    headText: "The Standard & Poor's 500 index",
                    profileImg: "/img/education/sflag_us.svg",
                    text: "The Standard & Poor's 500® Index (SP500) is widely regarded as the best single gauge of large-cap U.S. equities and is one of the most followed equity indices in the world. There is over USD 13.5 trillion benchmarked to the SP500 (as of 31 December 2020) and it captures approximately 80% of the U.S. market capitalization. The index tracks 500 of the largest companies listed on the New York Stock Exchange and the NASDAQ including, Apple Inc, Amazon, Johnson and Johnson, Microsoft Corp, Facebook Inc, etc.",
                    img1: "/img/education/S&P1.svg",
                    img2: "/img/education/S&P2.svg",
                    img3: "/img/education/S&P3.svg",
                    text1: "Trades 23 hours",
                    text2: "Trading in S&P 500® futures covers the full trading hours of Bursa Malaysia",
                    text3: "Over $13.5 trillion",
                    text4: "in assets benchmarked to the index",
                    text5: "Captures ≈ 80%",
                    text6: "of U.S. equity market capitalization",
                    tableHeading: "S&P 500"
                },
            ],
            showModaData: {},
            call: [],
            put: [],
            ranking: [],
            alertResult: {
                data: []
            },
            currencyType: null

        }
    }
    componentDidMount() {
        this.setState({
            showModaData: this.state.modalData[this.props.keyValue - 1]
        })
        this.getAlertData(this.props.keyValue)
        this.getWarrantData(this.props.keyValue)
        this.getIndexData(this.props.keyValue)

    }
    getChartOption() {
        const array1 = [];
        const array2 = [];
        var currName = '';
        if (typeof this.state.alertResult.data == 'string') {
            const temp = (this.state.alertResult.data).split("|")
            var array = temp
            currName = this.state.currencyType

            for (var i = 0; i < array.length; i++) {
                var split = array[i].split(";");
                array1.push(split[0]);
                array2.push(Number(split[1]));
            }
            // const array3 = array1

        } else {
            currName = this.state.currencyType
            for (var i = 0; i < this.state.alertResult.data.length; i++) {
                array1.push(this.state.alertResult.data[i].time);
                array2.push(Number(this.state.alertResult.data[i].close));
            }
        }
        const array3 = array1
        const options = {

            lang: {
                thousandsSep: ','
            },
            chart: {
                type: "area",
            },
            title: {
                text: "",
            },
            subtitle: {
                text: "",
            },
            credits: {
                text: "",
            },
            graphs: {
                fillAlphas: 0.2,
                fillColors: "#097CCF",
                lineColor: "#097CCF",
                negativeFillAlphas: 0,
            },
            tooltip: {
                useHTML: true,
                borderColor: '#097CCF',
                borderWidth: 2,
                style: {
                    fontWeight: 800,
                    fontSize: 13,
                    zIndex: 100,
                },
                //pointFormat: `<b>Underlying Price (${currName}) :</b> {point.y:.f}`,
                formatter: function () {
                    // The first returned item is the header, subsequent items are the
                    // points
                    return ['<b>' + this.x + '</b>'].concat(
                        this.points ?
                            this.points.map(function (point) {
                                return '<b>Underlying Price (' + currName + '): ' + point.y.toLocaleString();
                            }) : []
                    );
                },
                split: true,
                xDateFormat: '%d/%m/%y'
            },
            xAxis: {
                categories: array3.map((d) => moment(d).format("DD/MM")),
                tickInterval: 33,
                title: {
                    text: "",
                },
                crosshair: {
                    width: 1,
                    color: "red",
                    zIndex: "10",
                },
                gridLineColor: "#FAFAFA",
                gridLineWidth: 1,

            },
            yAxis: {
                lineWidth: 1,
                tickWidth: 1,
                gridLineColor: "#FAFAFA",
                gridLineWidth: 1,
                floor: Math.min(...array2),
                categories: array2.map((d) => d),
                tickInterval: Math.min(...array2) >= 4000 ? 1000 : (Math.min(...array2) < 4000 && Math.min(...array2) > 3400) ? 400 : (Math.min(...array2) < 3399 && Math.min(...array2) > 1300) ? 30 : 2,
                title: {
                    text: "",
                },
                labels: {
                    formatter: function () {
                        return this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    },
                },
            },
            plotOptions: {
                area: {
                    lineWidth: 1,
                    lineColor: '#097CCF',
                    marker: {
                        enabled: true,
                        symbol: "circle",
                        radius: 0,
                        states: {
                            hover: {
                                enabled: false,
                                lineWidth: 0,
                                lineWidthPlus: 0,
                                radius: 0,
                                radiusPlus: 0
                            },
                        },
                    },
                    series: {
                        stacking: "normal",
                    },
                },
            },
            series: [{
                showInLegend: false,
                name: "",
                animation: false,
                fillColor: "#cce3f3",
                data: array2,
            }]
        }
        return options
    }
    getAlertData(keyValue) {
        var ric = keyValue === 1 ? "NQc1" : keyValue === 2 ? "YMc1" : keyValue === 3 ? "HSIc1" :
            keyValue === 4 ? "HHTIc1" : keyValue === 5 ? "SSIcv1" : "ESc1"
        var qid = Date.now()
        const url =
            MQSGUtil.getAPIBasePath() + "/alert?TYPE=JSON&RIC=" + ric + "&PERIODICITY=Y&d=" + qid;
        fetch(url)
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({ alertResult: result })
                },
                (error) => {

                }
            );
    }
    getWarrantData(keyValue) {
        var qid = Date.now()
        const url =
            MQSGUtil.getAPIBasePath() + "/MarketDataJSON?type=index_sortbyname&lmt=all&qid=" + qid;
        fetch(url)
            .then((res) => res.json())
            .then(
                (result) => {
                    var warrantIssue = result.ranking
                    var newData = warrantIssue.filter((d) => d.underlying_ticker === "HSI")
                    var newData1 = warrantIssue.filter((d) => d.underlying_ticker === "DJIA")
                    var newData2 = warrantIssue.filter((d) => d.underlying_ticker === "HSTECH")
                    var newData3 = warrantIssue.filter((d) => d.underlying_ticker === "SiMSCI")
                    var newData4 = warrantIssue.filter((d) => d.underlying_ticker === "N225")
                    var newData5 = warrantIssue.filter((d) => d.underlying_ticker === "S&P500")
                    var newData6 = warrantIssue.filter((d) => d.underlying_ticker === "NDX")
                    this.setState({
                        ranking: (keyValue === 1 ? newData6 : keyValue === 2 ? newData1 : keyValue === 3 ? newData :
                            keyValue === 4 ? newData2 : keyValue === 5 ? newData4 : newData5)
                    })
                },
                (error) => {

                }
            );
    }
    getIndexData(keyValue) {
        var ticker = keyValue === 1 ? "NDX" : keyValue === 2 ? "DJIA" : keyValue === 3 ? "HSI" :
            keyValue === 4 ? "HSTECH" : keyValue === 5 ? "N225" : "S%26P500"
        var qid = Date.now()
        const url =
            MQSGUtil.getAPIBasePath() + "/IndexWarrantsJSON?ticker=" + ticker + "&qid=" + qid;
        fetch(url)
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result.CALL.length == 0) {
                        return;
                    }
                    var currType = result.CALL[0].underlying_ric;
                    var type = '';
                    if (currType == "ESc1") {
                        type = "USD"
                    } else if (currType == "NQc1") {
                        type = "USD"
                    } else if (currType == "YMc1") {
                        type = "USD"
                    } else if (currType == "FKLIc1") {
                        type = "MYR"
                    } else if (currType == 'SSIcv1') {
                        type = "JPY"
                    } else if (currType == 'SSGc1') {
                        type = "SGD"
                    } else {
                        type = "HKD"
                    }
                    this.setState({
                        call: result.CALL,
                        put: result.PUT,
                        currencyType: type
                    })
                },
                (error) => {

                }
            );
    }
    setOpen() {
        this.props.setValue(false)
    }
    render() {
        const style = {
            width: "300px",
            height: "470px",
        };
        return (
            <div id="ppBoxHTML1" className="overlay indexModal" style={{ display: this.props.show ? 'block' : 'none' }}>
                <div className="overlay-inner" style={{ background: '#F5F6F7', width: '85%' }}>
                    <div style={{ position: 'relative' }}>
                        <img
                            className="btn-close"
                            style={{}}
                            src="/img/education/icon-close-black.png"
                            onClick={() => this.setOpen(false)}
                        />
                        <div className="popupbox-content" style={{ paddingLeft: '15px', paddingRight: '15px' }}><div className="ppc" key="What-is-NDX">
                            <div className="row">
                                <div className="col-sm-12" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <img
                                        src={this.state.showModaData?.profileImg}
                                        style={{ width: '60px', paddingRight: '10px', margin: '0px' }} />
                                    <h2 className="ppc-h">{this.state.showModaData?.headText}</h2><h2>
                                    </h2></div>
                                <div className="col-sm-12" style={{ marginTop: '10px' }}>
                                    <p dangerouslySetInnerHTML={{ __html: this.state.showModaData != undefined ? this.state.showModaData.text : "", }}>

                                    </p>
                                </div>
                                <div className="col-sm-12" style={{ marginTop: '10px' }}>
                                    <div className="row rating-row mdHeight"    >
                                        <div className="col-md-4 " style={{ paddingLeft: '0px' }}>
                                            <div className="rating-item">
                                                <div className="rating-img"><img src={this.state.showModaData?.img1} /></div>
                                                <div className="rating-item-txtarea">
                                                    <h4 className="rating-item-ttl" style={{ fontWeight: 'bold' }}>
                                                        {this.state.showModaData?.text1}
                                                    </h4>
                                                    <p className="rating-item-txt" dangerouslySetInnerHTML={{ __html: this.state.showModaData != undefined ? this.state.showModaData.text2 : "", }}></p>
                                                    {/* <p className="rating-item-txt">
                                                        {this.state.showModaData?.text2}
                                                    </p> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="rating-item">
                                                <div className="rating-img"><img src={this.state.showModaData?.img2} /></div>
                                                <div className="rating-item-txtarea">
                                                    <h4 className="rating-item-ttl" style={{ fontWeight: 'bold' }}>
                                                        {this.state.showModaData?.text3}
                                                    </h4>
                                                    <p className="rating-item-txt">
                                                        {this.state.showModaData?.text4}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="rating-item">
                                                <div className="rating-img"><img style={{ width: '43px', }} src={this.state.showModaData?.img3} /></div>
                                                <div className="rating-item-txtarea">
                                                    <h4 className="rating-item-ttl" style={{ fontWeight: 'bold' }}>
                                                        {this.state.showModaData?.text5}
                                                    </h4>
                                                    <p className="rating-item-txt">
                                                        {this.state.showModaData?.text6}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="call_table col-xs-12" style={{}}>
                                    <div className="tw_table row">
                                        <div className="call_td_0 call_td col-md-3 mdPadding" style={this.state.call?.length === 0 ? { display: 'none' } : {}} >
                                            <div className="tw_warrant">
                                                <div colSpan={2} className="tw_title">
                                                    <div className="tw_type">Call</div>
                                                    <div className="tw_ticker">{this.state.call?.map((d) => d.dsply_nmll)[0]}</div>
                                                </div>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '40%' }}>
                                                                Price:
                                                            </td>
                                                            <td style={{ width: '60%' }}>
                                                                SGD <span className="tw_BID">{this.state.call?.map((d) => Number(d.BID).toFixed(3))[0]}</span> <br />
                                                                (<span
                                                                    className={this.state.call.length > 0 && this.state.call[0].BID_PCTCHNG && this.state.call[0].BID_PCTCHNG.toString() <= 0 ? "tw_BID_PCTCHNG down" : "tw_BID_PCTCHNG up"}
                                                                >{this.state.call.length > 0 && this.state.call[0].BID_PCTCHNG ? `${parseFloat(this.state.call[0].BID_PCTCHNG).toFixed(1)}%` : "-"}
                                                                </span>)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: '40%' }}>
                                                                Strike:
                                                            </td>
                                                            <td className="tw_strike" style={{ width: '60%' }}>{this.state.call?.map((d) => d.exercise_price)[0]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: '40%' }}>
                                                                Expiry:
                                                            </td>
                                                            <td className="tw_expiry" style={{ width: '60%' }}>{moment(this.state.call?.map((d) => d.maturity)[0]).format('DD MMM YY')}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: '40%' }}>
                                                                Eff gearing:
                                                            </td>
                                                            <td className="tw_eff" style={{ width: '60%' }}>{this.state.call && this.state.call[0] && this.state.call[0].effective_gearing ? parseFloat(this.state.call[0].effective_gearing).toFixed(1) : "-"}</td>
                                                        </tr>
                                                    </tbody></table>
                                            </div>
                                        </div>
                                        <div className="put_td_0 put_td col-md-3" style={this.state.put?.length === 0 ? { display: 'none' } : {}}>
                                            <div className="tw_warrant">
                                                <div colSpan={2} className="tw_title">
                                                    <div className="tw_type">Put</div>
                                                    <div className="tw_ticker">{this.state.put?.map((d) => d.dsply_nmll)[0]}</div>
                                                </div>
                                                <table style={{ width: '100%' }}>
                                                    <tbody><tr>
                                                        <td style={{ width: '40%' }}>
                                                            Price:
                                                        </td>
                                                        <td style={{ width: '60%' }}>
                                                            SGD <span className="tw_BID">
                                                                {this.state.put?.map((d) => Number(d.BID).toFixed(3))[0]}</span> <br />
                                                            (<span
                                                                className={this.state.put.length > 0 && this.state.put[0].BID_PCTCHNG && this.state.put[0].BID_PCTCHNG.toString() <= 0 ? "tw_BID_PCTCHNG down" : "tw_BID_PCTCHNG up"}
                                                            >
                                                                {this.state.put.length > 0 && this.state.put[0].BID_PCTCHNG ? parseFloat(this.state.put[0].BID_PCTCHNG).toFixed(1) + "%" : "-"}

                                                            </span>)
                                                        </td>
                                                    </tr>
                                                        <tr>
                                                            <td style={{ width: '40%' }}>
                                                                Strike:
                                                            </td>
                                                            <td className="tw_strike" style={{ width: '60%' }}>{this.state.put?.map((d) => d.exercise_price)[0]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: '40%' }}>
                                                                Expiry:
                                                            </td>
                                                            <td className="tw_expiry" style={{ width: '60%' }}>{moment(this.state.put?.map((d) => d.maturity)[0]).format('DD MMM YY')}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: '40%' }}>
                                                                Eff gearing:
                                                            </td>
                                                            <td className="tw_eff" style={{ width: '60%' }}>
                                                                {this.state.put && this.state.put[0] && this.state.put[0].effective_gearing ? parseFloat(this.state.put[0].effective_gearing).toFixed(1) : "-"}
                                                            </td>
                                                        </tr>
                                                    </tbody></table>
                                            </div>
                                        </div>
                                        <div className="call_td_1 call_td col-md-3" style={this.state.call?.length < 2 ? { display: 'none' } : {}}>
                                            <div className="tw_warrant">
                                                <div colSpan={2} className="tw_title">
                                                    <div className="tw_type">Call</div>
                                                    <div className="tw_ticker">{this.state.call?.map((d) => d.dsply_nmll)[1]}</div>
                                                </div>
                                                <table style={{ width: '100%' }}>
                                                    <tbody><tr>
                                                        <td style={{ width: '40%' }}>
                                                            Price:
                                                        </td>
                                                        <td style={{ width: '60%' }}>
                                                            SGD <span className="tw_BID">
                                                                {this.state.call?.map((d) => Number(d.BID).toFixed(3))[1]}
                                                            </span> <br />
                                                            (<span
                                                                className={this.state.call && this.state.call[1]?.BID_PCTCHNG && this.state.call[1]?.BID_PCTCHNG.toString() <= 0 ? "tw_BID_PCTCHNG down" : "tw_BID_PCTCHNG up"}
                                                            >
                                                                {this.state.call && this.state.call[1]?.BID_PCTCHNG ? `${parseFloat(this.state.call[1]?.BID_PCTCHNG).toFixed(1)}%` : "-"}
                                                            </span>)
                                                        </td>
                                                    </tr>
                                                        <tr>
                                                            <td style={{ width: '40%' }}>
                                                                Strike:
                                                            </td>
                                                            <td className="tw_strike" style={{ width: '60%' }}>{this.state.call?.map((d) => d.exercise_price)[1]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: '40%' }}>
                                                                Expiry:
                                                            </td>
                                                            <td className="tw_expiry" style={{ width: '60%' }}>{moment(this.state.call?.map((d) => d.maturity)[0]).format('DD MMM YY')}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: '40%' }}>
                                                                Eff gearing:
                                                            </td>
                                                            <td className="tw_eff" style={{ width: '60%' }}>
                                                                {this.state.call && this.state.call[1] && this.state.call[1].effective_gearing ? parseFloat(this.state.call[1].effective_gearing).toFixed(1) : "-"}
                                                            </td>
                                                        </tr>
                                                    </tbody></table>
                                            </div>
                                        </div>
                                        <div className="put_td_1 put_td col-md-3" style={this.state.put?.length < 2 ? { display: 'none' } : {}}>
                                            <div className="tw_warrant">
                                                <div colSpan={2} className="tw_title">
                                                    <div className="tw_type">Put</div>
                                                    <div className="tw_ticker">{this.state.put?.map((d) => d.dsply_nmll)[1]}</div>
                                                </div>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '40%' }}>
                                                                Price:
                                                            </td>
                                                            <td style={{ width: '60%' }}>
                                                                SGD <span className="tw_BID">
                                                                    {this.state.put?.map((d) => Number(d.BID).toFixed(3))[1]}
                                                                </span> <br />
                                                                (<span
                                                                    className={this.state.put.length > 1 && this.state.put[1].BID_PCTCHNG && this.state.put[1].BID_PCTCHNG.toString() <= 0 ? "tw_BID_PCTCHNG down" : "tw_BID_PCTCHNG up"}
                                                                >
                                                                    {this.state.put.length > 1 && this.state.put[1].BID_PCTCHNG ? parseFloat(this.state.put[1].BID_PCTCHNG).toFixed(1) + "%" : "-"}

                                                                </span>)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: '40%' }}>
                                                                Strike:
                                                            </td>
                                                            <td className="tw_strike" style={{ width: '60%' }}>{this.state.put?.map((d) => d.exercise_price)[1]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: '40%' }}>
                                                                Expiry:
                                                            </td>
                                                            <td className="tw_expiry" style={{ width: '60%' }}>{moment(this.state.put?.map((d) => d.maturity)[0]).format('DD MMM YY')}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: '40%' }}>
                                                                Eff gearing:
                                                            </td>
                                                            <td className="tw_eff" style={{ width: '60%' }}>{this.state.put && this.state.put[1] && this.state.put[1].effective_gearing ? parseFloat(this.state.put[1].effective_gearing).toFixed(1) : "-"}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Link className={`viewLM ${this.state.ranking && this.state.ranking[0]?.ticker}`} target="_blank" to={`/tools/livematrix/${this.state.ranking && this.state.ranking[0]?.ticker}`}>
                                    <div className="vlmbtn bgcolor-06 btn" style={{ backgroundColor: "#fecc0a" }}>
                                        View matrix
                                    </div>
                                </Link>
                                <div className="col-sm-12" style={{ clear: 'both' }}>
                                    <div id="chartbox" style={{ width: '100%', overflow: 'hidden', textAlign: 'left' }}>
                                        <div style={{ position: 'relative' }}>
                                            <div style={{ overflow: 'hidden', position: 'relative', textAlign: 'left', cursor: 'default', width: '100%' }}>
                                                {/* {this.state.alertResult && this.state.currencyType && <HighchartsReact */}
                                                {this.state.alertResult && <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={this.getChartOption()}
                                                    style={style}
                                                />}
                                            </div>
                                        </div></div>
                                </div>
                                <Link className={`viewC col-xs-12 ${this.state.ranking?.map((d) => d.underlying_ticker)[0]}`}
                                    target="_blank"
                                    to={`/tools/underlying/${this.state.ranking?.map((d) => d.underlying_ticker)[0]}`}>
                                    <div className="vcbtn bgcolor-06 btn">View charts</div>
                                </Link>
                                <div className="col-sm-12" style={{ clear: 'both' }}>
                                    <h2 className='table-heading'>{this.state.showModaData?.tableHeading} warrants issued by Macquarie</h2>
                                    <table id="warranttbl" className="table table-striped table-closed table-responsive">
                                        <thead>
                                            <tr>
                                                <th>Warrant Name</th>
                                                {/* <th>Underlying</th> */}
                                                <th>Type</th>
                                                <th className="hidden-xs">Last bid price</th>
                                                <th className="hidden-xs">Change</th>
                                                <th className="hidden-xs">Change (%)</th>
                                                <th className="hidden-xs">Volume ('000)</th>
                                                <th>Expiry</th>
                                                <th className="hidden-xs">Last trading date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.ranking && this.state.ranking.map((d) =>
                                                <tr className="rowtmpl" idx={0}>
                                                    <td className="col_dsply_nmll"><a target="_blank" href={`/tools/livematrix/${d.ticker}`}>{d.dsply_nmll}</a></td>
                                                    {/* <td className="col_underlying_ticker"><a target="_blank" href={`/my/tools/underlying/${d.underlying_ric}`}>{d.underlying_ticker}</a></td> */}
                                                    <td className="col_type">{d.type == 'PUT' ? "P" : 'C'}</td>
                                                    <td className="hidden-xs col_BID">{d.BID}</td>
                                                    <td className={d.BID_NETCHNG.includes("-") ? "hidden-xs col_BID_NETCHNG down" : "hidden-xs col_BID_NETCHNG up"}>{d.BID_NETCHNG}</td>
                                                    <td className={d.BID_PCTCHNG.includes("-") ? "hidden-xs col_BID_PCTCHNG down" : "hidden-xs col_BID_PCTCHNG up"}>{d.BID_PCTCHNG}</td>
                                                    <td className="hidden-xs col_ACVOL_1_SCALED">{d.ACVOL_1_SCALED}</td>
                                                    <td className="col_maturity">{d.maturity}</td>
                                                    <td className="hidden-xs col_last_trading_date">{d.last_trading_date}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div></div>
                    </div>
                </div>
            </div>
        )
    }

}